import { createSlice } from "@reduxjs/toolkit";
import userMSTCruds from "../../_apis/cruds/userMSTCrud";
import contactusCruds from "../../_apis/cruds/contactusCrud";
import customerMSTCruds from "../../_apis/cruds/customerMSTCrud";
import { TOKEN_NAME } from "../../_apis/setupAxios";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";
import { menuPageSlice } from "./menuPageRedux";
import { outletShiftActions, outletShiftDTLSlice } from "./outletShiftRedux";
import { homeActions } from "./homeRedux";
import { cartActions } from "./cartRedux";
import { outletMSTActions } from "./outletMSTRedux";
import { customerMSTActions } from "./customerRedux";
import { useSelector } from "react-redux";
import { paymentModeMSTSlice } from "./paymentModeRedux";

const REDUCER_NAME = "auth";

const TYPES = {
  LOGIN_BY_IP: "loginByIp",
  LOGIN_BY_USER_PASSWORD: "loginByUserPassword",
  SIGNIN_OUTLET: 'signinOutlet',
  AUTHENTICATING: "authenticating",
  LOADING_OUTLET: "loadingOutlet",
  NEW_USER: "newUser",
  FORGOT_PASSWORD: "forgotPassword",
  CONTACTUS_FEEDBACK: "contactUsFeedback",
  LOGIN_BY_Social: "loginBySocial",
};

const selectCurrentOutlet = (state) => state?.outletMST?.currentOutlet?.data
const allCurrentOutlet = (state) => state?.outletMST?.allOutlet?.data
const authState = (state) => state


export const authSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    loading: Object.values(TYPES).reduce(
      (occ, cur) => ({ ...occ, [cur]: false }),
      {}
    ),
    error: Object.values(TYPES).reduce(
      (occ, cur) => ({ ...occ, [cur]: null }),
      {}
    ),
    data: Object.values(TYPES).reduce(
      (occ, cur) => ({ ...occ, [cur]: null }),
      {}
    ),
    authUser: null,
    authCust: null,
    authOutlet: null,
  },
  reducers: {
    catchError: (state, action) => {
      state.loading[action.payload.type] = false;
      state.error[action.payload.type] = action.payload.error.message;
    },
    startCall: (state, action) => {
      state.error[action.payload.type] = null;
      state.loading[action.payload.type] = true;
    },
    stopCall: (state, action) => {
      state.error[action.payload.type] = null;
      state.loading[action.payload.type] = false;
    },
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
    setAuthCust: (state, action) => {
      state.authCust = action.payload;
    },
    setAuthOutlet: (state, action) => {
      state.authOutlet = action.payload;
    },
    dataRecieved: (state, action) => {
      state.error[action.payload.type] = null;
      state.loading[action.payload.type] = false;
      state.data[action.payload.type] = action.payload.data;
    },
       
  },
});




const startCall = (type) => authSlice.actions.startCall({ type });
const stopCall = (type) => authSlice.actions.stopCall({ type });
const catchError = (type, error) =>
  authSlice.actions.catchError({ type, error });
const dataRecieved = (type, data) =>
  authSlice.actions.dataRecieved({ type, data });
export const authActions = {
  loginByIp: () => (dispatch) => {
    dispatch(startCall(TYPES.LOGIN_BY_IP));
    return userMSTCruds
      .createUserByIp()
      .then((res) => {
        dispatch(stopCall(TYPES.LOGIN_BY_IP));
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(catchError(TYPES.LOGIN_BY_IP, err));
        return Promise.reject(err);
      });
  },
  sendOTP: (userName) => (dispatch) => {
    dispatch(startCall(TYPES.FORGOT_PASSWORD));
    return userMSTCruds
      .forgotPassword({ param: {}, body: { username: userName, remarks: "Pippos" } })
      .then((res) => {
        dispatch(stopCall(TYPES.FORGOT_PASSWORD));
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(catchError(TYPES.FORGOT_PASSWORD, err));
        return Promise.reject(err);
      });
  },
  ChangePassword: (oldPassword, newPassword, username) => (dispatch) => {
    dispatch(startCall(TYPES.FORGOT_PASSWORD));
    return userMSTCruds
      .changePassword({
        param: {},
        body: {
          oldPassword: oldPassword,
          newPassword: newPassword,
          username: username,
        },
      })
      .then((res) => {
        dispatch(stopCall(TYPES.FORGOT_PASSWORD));
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(catchError(TYPES.FORGOT_PASSWORD, err));
        return Promise.reject(err);
      });
  },
  ResetPassword: (newPassword, username, otp) => (dispatch) => {
    dispatch(startCall(TYPES.FORGOT_PASSWORD));
    return userMSTCruds
      .resetPassword({
        param: {},
        body: { newPassword: newPassword, otp: otp, username: username, remarks: "Pippos" },
      })
      .then((res) => {
        dispatch(stopCall(TYPES.FORGOT_PASSWORD));
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(catchError(TYPES.FORGOT_PASSWORD, err));
        return Promise.reject(err);
      });
  },
  contactUS: (obj) => (dispatch) => {
    dispatch(startCall(TYPES.CONTACTUS_FEEDBACK));
    return contactusCruds
      .create({
        param: {},
        body: {
          contactUsEmail: obj.contactUsEmail,
          contactUsMessage: obj.contactUsMessage,
          contactUsMobile: obj.contactUsMobile,
          contactUsName: obj.contactUsName,
          customerMSTId: obj.customerMSTId,
        },
      })
      .then((res) => {
        dispatch(stopCall(TYPES.CONTACTUS_FEEDBACK));
        return Promise.resolve(res);
      })
      .catch((err) => {
        // console.log("err reset" + err);
        dispatch(catchError(TYPES.CONTACTUS_FEEDBACK, err));
        return Promise.reject(err);
      });
  },
  createUser: (user) => (dispatch) => {
    dispatch(startCall(TYPES.NEW_USER));
    user = { ...user, remarks: "Pippos" }
    return customerMSTCruds
      .signUpCustomer({ body: user })
      .then((res) => {
        dispatch(authSlice.actions.setAuthUser(res.login.userMST));
        dispatch(authSlice.actions.setAuthCust(res.customer));
        dispatch(orderMSTActions.setGuestCustomer(null));
        dispatch(dataRecieved(TYPES.NEW_USER, res));
        localStorage.setItem(TOKEN_NAME, res.login.jwtToken);
        console.log('success')
        return Promise.resolve(res);
      })
      .catch((err) => {
        console.log('Failed')
        dispatch(catchError(TYPES.NEW_USER, err));
        Promise.reject(err);
      });
  },
  updateUser: (user) => (dispatch) => {
    dispatch(startCall(TYPES.NEW_USER));
    return customerMSTCruds
      .updateSignUpCustomer({ body: user })
      .then((res) => {
        dispatch(dataRecieved(TYPES.NEW_USER, res));
      })
      .catch((err) => {
        dispatch(catchError(TYPES.NEW_USER, err));
      });
  },
  loginByUserNamePassword: (user) => (dispatch) => {
    dispatch(startCall(TYPES.LOGIN_BY_USER_PASSWORD));
    dispatch(startCall(TYPES.SIGNIN_OUTLET));

    return userMSTCruds
      .loginUser({ body: user })
      .then((res) => {
        localStorage.setItem(TOKEN_NAME, res.jwtToken);
        dispatch(dataRecieved(TYPES.LOGIN_BY_USER_PASSWORD, res));
        dispatch(authSlice.actions.setAuthUser(res.userMST));
        const custMST = {
          customerFirstName: res.firstName,
          customerLastName: res.lastName,
          primaryContact: res.mobileNumber,
          primaryEmail: res.emailId,
          customerMSTId: null,
        };
        dispatch(stopCall(TYPES.SIGNIN_OUTLET));
        dispatch(authSlice.actions.setAuthCust(custMST));
        dispatch(orderMSTActions.setGuestCustomer(null));
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(stopCall(TYPES.SIGNIN_OUTLET));
        dispatch(catchError(TYPES.LOGIN_BY_USER_PASSWORD, err));
        return Promise.reject(err);
      });
  },
  logOut: () => (dispatch) => {
    dispatch(startCall(TYPES.LOGIN_BY_USER_PASSWORD));
    localStorage.removeItem(TOKEN_NAME);
    debugger
    dispatch(dataRecieved(TYPES.LOGIN_BY_USER_PASSWORD, null));
    dispatch(authSlice.actions.setAuthUser(TYPES.LOGIN_BY_USER_PASSWORD, null));
    dispatch(authSlice.actions.setAuthCust(null));

    dispatch(stopCall(TYPES.LOGIN_BY_USER_PASSWORD));
  },
  setAuthCustomer: (data) => (dispatch) => {
    dispatch(authSlice.actions.setAuthCust(data));
  },
  loginByScoial: (user) => (dispatch) => {
    dispatch(startCall(TYPES.LOGIN_BY_Social));
    user = {...user, remarks: "Pippos"} 
    return customerMSTCruds
      .signUpCustomerBySocial({ body: user })
      .then((res) => {
        dispatch(authSlice.actions.setAuthUser(res.login.userMST));
        dispatch(authSlice.actions.setAuthCust(res.customer));
        dispatch(orderMSTActions.setGuestCustomer(null));
        dispatch(dataRecieved(TYPES.LOGIN_BY_Social, res));
        localStorage.setItem(TOKEN_NAME, res.login.jwtToken);
      })
      .catch((err) => {
        dispatch(catchError(TYPES.LOGIN_BY_Social, err));
      });
  },
  loginByGuest: () => (dispatch) => {
    const stop = () => dispatch(stopCall(TYPES.AUTHENTICATING));
    const catchErr = (err) => dispatch(catchError(TYPES.AUTHENTICATING, err));
    const addOutletSystemTokens = (token, user) => {
      if (token && user) {
        localStorage.setItem(TOKEN_NAME, token);
        let outletSelectionStorage = JSON.parse(localStorage.getItem("selected-outlet"));
        userMSTCruds
          .addClaimToToken({
            param: { key: "outlet" },
            body: JSON.stringify(outletSelectionStorage?.[0]?.outletCode),
          })
          .then((setOutletRes) => {
            localStorage.setItem(TOKEN_NAME, setOutletRes);
            userMSTCruds
              .addClaimToToken({
                param: { key: "system" },
                body: JSON.stringify("PIZZAPORTAL"),
              })
              .then((setSystemRes) => {
                localStorage.setItem(TOKEN_NAME, setSystemRes);
                dispatch(authSlice.actions.setAuthUser(user));
                stop();
              })
              .catch((loginByIpErr) => {
                catchErr({
                  ...loginByIpErr,
                  message:
                    "Can't get token for system, Error: " +
                    loginByIpErr.message,
                });
              });
          })
          .catch((loginByIpErr) => {
            catchErr({
              ...loginByIpErr,
              message:
                "Can't get token for outlet, Error: " + loginByIpErr.message,
            });
          });
      } else {
        catchErr({ message: "Something went wrong user or token not found." });
      }
    };
    return userMSTCruds
      .createUserByIp()
      .then((res) => {
        //dispatch(authSlice.actions.setAuthUser(res.userMST));
        //localStorage.setItem(TOKEN_NAME, res.jwtToken);
        const token = res.jwtToken;
        const user = res.userMST;
        addOutletSystemTokens(token, user);
      })
      .catch((err) => {
        dispatch(catchError(TYPES.AUTHENTICATING, err));
      });
  },

  // createUserIp : () => (dispatch) => {
  //   return new Promise((resolve, reject) => {

  //   const start = () => dispatch(startCall(TYPES.AUTHENTICATING));
  //   const stop = () => dispatch(stopCall(TYPES.AUTHENTICATING));
  //   const catchErr = (err) => dispatch(catchError(TYPES.AUTHENTICATING, err));
  //   start();
  //   userMSTCruds
  //           .createUserByIp()
  //           .then((loginByIpRes) => {
  //             const token = loginByIpRes.jwtToken;
  //             localStorage.setItem(TOKEN_NAME, token);
  //             stop()
  //             resolve()
             
  //           })
  //           .catch((loginByIpErr) => {
  //             stop()
  //             reject()
  //             catchErr({
  //               ...loginByIpErr,
  //               message:
  //                 "Can't login as a guest, Error: " + loginByIpErr.message,
  //             });
  //           });

  //         });
  // },

  // authenticateUser: (selectedOutlet, isLoad) => (dispatch,getState) => {
  //   return new Promise((resolve, reject) => {
  //     debugger
  //     const currentOutlet = selectCurrentOutlet(getState());
  //     const isOutletChanged = JSON.stringify(selectedOutlet) !== JSON.stringify(currentOutlet?.[0]);

  //       const start = () => dispatch(startCall(TYPES.LOADING_OUTLET));
  //       const startAuth = () => isLoad &&  dispatch(startCall(TYPES.AUTHENTICATING));
  //       const stop = () => dispatch(stopCall(TYPES.LOADING_OUTLET));
  //       const stopAuth = () => isLoad && dispatch(stopCall(TYPES.AUTHENTICATING));
  //       const catchErr = (err) =>  dispatch(catchError(TYPES.AUTHENTICATING, err));
  //       const addOutletSystemTokens = (token, user) => {
  //     if (token && user) {
  //         console.log(selectedOutlet)
            


  //       localStorage.setItem(TOKEN_NAME, token);
  //         console.log(selectedOutlet)
  //         if(selectedOutlet){
  //           localStorage.setItem('selected-outlet', JSON.stringify([selectedOutlet]));
  //         }

  //         if(selectedOutlet){
  //       userMSTCruds
  //         .addClaimToToken({
  //           param: { key: "outlet" },
  //           body: JSON.stringify(selectedOutlet?.outletCode),
  //         })
  //         .then((setOutletRes) => {
  //           localStorage.setItem(TOKEN_NAME, setOutletRes);
  //           userMSTCruds
  //             .addClaimToToken({
  //               param: { key: "system" },
  //               body: JSON.stringify("PIZZAPORTAL"),
  //             })
  //             .then((setSystemRes) => {
  //               dispatch(stopCall(TYPES.SIGNIN_OUTLET));
  //               dispatch(menuPageSlice.actions.clearAllItems())
  //              dispatch(outletShiftDTLSlice.actions.clearShift())
  //              dispatch(homeActions.getMenuGroupItems());  
  //               localStorage.setItem(TOKEN_NAME, setSystemRes);
  //               dispatch(authSlice.actions.setAuthUser(user));
  //               dispatch(outletShiftActions.getOutletShiftDTL());
  //               stop();
  //               stopAuth();
  //               debugger
  //               resolve()  
  //             })
  //             .catch((loginByIpErr) => {
  //               catchErr({
  //                 ...loginByIpErr,
  //                 message:
  //                   "Can't get token for system, Error: " +
  //                   loginByIpErr.message,
  //               });
  //             });
  //         })
  //         .catch((loginByIpErr) => {
  //           catchErr({
  //             ...loginByIpErr,
  //             message:
  //               "Can't get token for outlet, Error: " + loginByIpErr.message,
  //           });
  //         });
  //       } else {
  //         reject()
  //         stop()
  //         stopAuth()
  //     } 
  //     } else {
  //       stop()
  //       stopAuth()
  //       catchErr({ message: "Something went wrong user or token not found." });
      
  //   };
  // }
  
  //   start();
  //   debugger
  //   startAuth();
 
  //   userMSTCruds
  //     .getLoggedInUser()
  //     .then((res) => {
  //       debugger
  //       dispatch(authSlice.actions.setAuthCust(res.customerMST));
  //       addOutletSystemTokens(localStorage.getItem(TOKEN_NAME), res.userMST);
  //       // stop()
  //       // resolve();
  //     })
  //     .catch((err) => {
  //       localStorage.removeItem(TOKEN_NAME);
  //       if (err?.status === 401) {
  //       // if (err?.status === 401) {
  //         userMSTCruds
  //           .createUserByIp()
  //           .then((loginByIpRes) => {              
  //             const token = loginByIpRes.jwtToken;
  //             const user = loginByIpRes.userMST;
  //             addOutletSystemTokens(token, user);
  //             resolve(); 
              
  //             return Promise.resolve();

  //           })
  //           .catch((loginByIpErr) => {
  //             catchErr({
  //               ...loginByIpErr,
  //               message:
  //                 "Can't login as a guest, Error: " + loginByIpErr.message,
  //             });
  //             reject(loginByIpErr);
  //             return Promise.reject(loginByIpErr);
  //           });
  //           // stop()
  //         } else {
  //         catchErr(err);
  //         reject(err);
  //       } 
  //     })
  //     .finally(() => {
  //       console.log("Order will clear ?")
  //       if (isOutletChanged) {
  //         console.log("Order will cleared")
  //         dispatch(cartActions.destroyOrderAndClear());
  //     }
  //     })
  //   })
   
  // },
  authenticateUser: (selectedOutlet, isLoad) => (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      const allOutlet = allCurrentOutlet(getState());
      const currentOutlet = selectCurrentOutlet(getState());
      const isOutletChanged = JSON.stringify(selectedOutlet) !== JSON.stringify(currentOutlet?.[0]);
      
    //   if (!isOutletChanged && selectedOutlet ) { 
    //     debugger
    //     // Outlet is the same, no need to call APIs or clear items
    //     resolve();
        
    //     return;
    // }

      
        const startAndAuth = () => {
          dispatch(startCall(TYPES.LOADING_OUTLET));
          if (isLoad) {
              dispatch(startCall(TYPES.AUTHENTICATING));
          }
      };
        const stopAndClear = () => {
          dispatch(stopCall(TYPES.LOADING_OUTLET));
          if (isLoad) {
              dispatch(stopCall(TYPES.AUTHENTICATING));
          }
      };
        const catchAndReject = (err) => {
        stopAndClear();
        dispatch(catchError(TYPES.AUTHENTICATING, err));
        return Promise.reject(err);
      };

        const addOutletSystemTokens = (token, user, customer) => {
          
      if (token && user) {
        localStorage.setItem(TOKEN_NAME, token);        
        if(selectedOutlet){
          
          localStorage.setItem('selected-outlet', JSON.stringify([selectedOutlet]));      
          dispatch(outletMSTActions.setCurrentOutlet([selectedOutlet]));
          
          dispatch(outletMSTActions.getCurrentOutlet(selectedOutlet?.outletCode));
        userMSTCruds
          .addClaimToToken({
            param: { key: "outlet" },
            body: JSON.stringify(selectedOutlet?.outletCode),
          })
          .then((setOutletRes) => {
            localStorage.setItem(TOKEN_NAME, setOutletRes);
            userMSTCruds
              .addClaimToToken({
                param: { key: "system" },
                body: JSON.stringify("PIZZAPORTAL"),
              })
              .then((setSystemRes) => {
                userMSTCruds
                .getLoggedInUser()
                .then((res) => {
                  debugger
                  dispatch(authSlice.actions.setAuthCust(res.customerMST));
                  dispatch(authSlice.actions.setAuthOutlet(res.OutletMST));
              })
                dispatch(stopCall(TYPES.SIGNIN_OUTLET));
                dispatch(menuPageSlice.actions.clearAllItems())
               dispatch(outletShiftDTLSlice.actions.clearShift())
               dispatch(paymentModeMSTSlice.actions.clearAllItems()) 
               dispatch(homeActions.getMenuGroupItems());  
               dispatch(homeActions.getOffers());
                localStorage.setItem(TOKEN_NAME, setSystemRes);
                dispatch(authSlice.actions.setAuthUser(user));
                dispatch(outletShiftActions.getOutletShiftDTL());
               
                if (customer && customer.customerMSTId) {
                  dispatch(customerMSTActions.getCustomerFav(customer.customerMSTId));
                  // dispatch(customerMSTActions.getCustomerHistory(customer.customerMSTId));
                  // dispatch(cartActions.loadSurcharges());
                }
                
              
                stopAndClear()
                resolve()  
              })
              .catch((loginByIpErr) => {
                catchAndReject({
                  ...loginByIpErr,
                  message:
                    "Can't get token for system, Error: " +
                    loginByIpErr.message,
                });
              });
          })
          .catch((loginByIpErr) => {
            catchAndReject({
              ...loginByIpErr,
              message:
                "Can't get token for outlet, Error: " + loginByIpErr.message,
            });
          });
        } else {
          resolve()
          stopAndClear()
      } 
      } else {
        stopAndClear()
        catchAndReject({ message: "Something went wrong user or token not found." });
      
    };
  }

  startAndAuth()
 
    userMSTCruds
      .getLoggedInUser()
      .then((res) => {
        addOutletSystemTokens(localStorage.getItem(TOKEN_NAME), res.userMST, res.customerMST);
      })
      .catch((err) => {
        localStorage.removeItem(TOKEN_NAME);
        if (err?.status === 401) {
        // if (err?.status === 401) {
          userMSTCruds
            .createUserByIp()
            .then((loginByIpRes) => {   
              debugger           
              const token = loginByIpRes.jwtToken;
              const user = loginByIpRes.userMST;
           addOutletSystemTokens(token, user);
           debugger
              resolve(); 
              return Promise.resolve();

            })
            .catch((loginByIpErr) => {
              catchAndReject({
                ...loginByIpErr,
                message:
                  "Can't login as a guest, Error: " + loginByIpErr.message,
              });
              reject(loginByIpErr);
              return Promise.reject(loginByIpErr);
            });
            // stop()
          } else {
            catchAndReject(err);
          reject(err);
        } 
      })
      .finally(() => {
        if (isOutletChanged) {     
          // localStorage.removeItem("address")
          dispatch(cartActions.destroyOrderAndClear());
      }
      })
    })
   
  },


  getLoggedInUserDetails: () => (dispatch) => {
    userMSTCruds
      .getLoggedInUser()
      .then((res) => {
        dispatch(authSlice.actions.setAuthCust(res.customerMST));
      })
      .catch((err) => {
        // console.log(err);
      });
  },
};


// authenticateUser: (selectedOutlet, isLoad) => (dispatch,getState) => {
//   return new Promise((resolve, reject) => {
//     const currentOutlet = selectCurrentOutlet(getState());
//     const isOutletChanged = JSON.stringify(selectedOutlet) !== JSON.stringify(currentOutlet?.[0]);

//     if (!isOutletChanged && selectedOutlet) {
//       // Outlet is the same, no need to call APIs or clear items
//       resolve();
//       return;
//   }

    
//       const startAndAuth = () => {
//         dispatch(startCall(TYPES.LOADING_OUTLET));
//         if (isLoad) {
//             dispatch(startCall(TYPES.AUTHENTICATING));
//         }
//     };
//       const stopAndClear = () => {
//         dispatch(stopCall(TYPES.LOADING_OUTLET));
//         if (isLoad) {
//             dispatch(stopCall(TYPES.AUTHENTICATING));
//         }
//     };
//       const catchAndReject = (err) => {
//       stopAndClear();
//       dispatch(catchError(TYPES.AUTHENTICATING, err));
//       return Promise.reject(err);
//     };

   
    

 
//       const addOutletSystemTokens = (token, user) => {
//     if (token && user) {
//         console.log(selectedOutlet)
//       localStorage.setItem(TOKEN_NAME, token);
//         console.log(selectedOutlet)
//         if(selectedOutlet){
//           localStorage.setItem('selected-outlet', JSON.stringify([selectedOutlet]));
//         }

//         if(selectedOutlet){
//       userMSTCruds
//         .addClaimToToken({
//           param: { key: "outlet" },
//           body: JSON.stringify(selectedOutlet?.outletCode),
//         })
//         .then((setOutletRes) => {
//           localStorage.setItem(TOKEN_NAME, setOutletRes);
//           userMSTCruds
//             .addClaimToToken({
//               param: { key: "system" },
//               body: JSON.stringify("PIZZAPORTAL"),
//             })
//             .then((setSystemRes) => {
//               dispatch(stopCall(TYPES.SIGNIN_OUTLET));
//               dispatch(menuPageSlice.actions.clearAllItems())
//              dispatch(outletShiftDTLSlice.actions.clearShift())
//              dispatch(homeActions.getMenuGroupItems());  
//               localStorage.setItem(TOKEN_NAME, setSystemRes);
//               dispatch(authSlice.actions.setAuthUser(user));
//               dispatch(outletShiftActions.getOutletShiftDTL());
//               stopAndClear()
//               debugger
//               resolve()  
//             })
//             .catch((loginByIpErr) => {
//               catchAndReject({
//                 ...loginByIpErr,
//                 message:
//                   "Can't get token for system, Error: " +
//                   loginByIpErr.message,
//               });
//             });
//         })
//         .catch((loginByIpErr) => {
//           catchAndReject({
//             ...loginByIpErr,
//             message:
//               "Can't get token for outlet, Error: " + loginByIpErr.message,
//           });
//         });
//       } else {
//         reject()
//         stopAndClear()
//     } 
//     } else {
//       stopAndClear()
//       catchAndReject({ message: "Something went wrong user or token not found." });
    
//   };
// }

// startAndAuth()

//   userMSTCruds
//     .getLoggedInUser()
//     .then((res) => {
//       debugger
//       dispatch(authSlice.actions.setAuthCust(res.customerMST));
//       addOutletSystemTokens(localStorage.getItem(TOKEN_NAME), res.userMST);
//     })
//     .catch((err) => {
//       localStorage.removeItem(TOKEN_NAME);
//       if (err?.status === 401) {
//       // if (err?.status === 401) {
//         userMSTCruds
//           .createUserByIp()
//           .then((loginByIpRes) => {              
//             const token = loginByIpRes.jwtToken;
//             const user = loginByIpRes.userMST;
//             addOutletSystemTokens(token, user);
//             resolve(); 
            
//             return Promise.resolve();

//           })
//           .catch((loginByIpErr) => {
//             catchAndReject({
//               ...loginByIpErr,
//               message:
//                 "Can't login as a guest, Error: " + loginByIpErr.message,
//             });
//             reject(loginByIpErr);
//             return Promise.reject(loginByIpErr);
//           });
//           // stop()
//         } else {
//           catchAndReject(err);
//         reject(err);
//       } 
//     })
//     .finally(() => {
//       console.log("Order will clear ?")
//       if (isOutletChanged) {
//         console.log("Order will cleared")
//         dispatch(cartActions.destroyOrderAndClear());
//     }
//     })
//   })
 
// },