import React, { useEffect, useState } from "react";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";
import "./navbar.scss";
import SignIn from "../../pages/userAccount/SignIn";
import { useDispatch, useSelector } from "react-redux";
import SignUp from "../../pages/userAccount/SignUp";
import ForgotPassword from "../../pages/userAccount/ForgotPassword";
import UserAccountPopUp from "../../pages/userAccount/UserAccountPopUp";
import Cart from "../../pages/menu/Cart";
import CustomDialog from "../dialog/CustomDialog";
import { cartActions, cartSlice } from "../../_redux/files/cartRedux";
import { hnhActions, hnhSlice } from "../../_redux/files/hnhRedux";
import { buildYourOwnActions } from "../../_redux/files/buildYourOwnRedux";
import { customizeActions } from "../../_redux/files/customizeRedux";
import {
  defaultTimeDiffForClearingCart,
  makeMinOrderValueToTwoDecimal,
} from "../../helpers/Utils";
import { deliveryChargesDTLActions } from "../../_redux/files/deliveryChargeRedux";
import { outletShiftActions } from "../../_redux/files/outletShiftRedux";
import { geographyMSTActions } from "../../_redux/files/geographyMSTRedux";

const Navbar = () => {
  // TEST
  const [cartTrackError, setCartTrackError] = useState(null);
  const [minOrderValue, setMinOrderValue] = useState(null);
  const {
    checkMinimum,
    subTotal,
    loadingGetMinimumOrder,
    orderTypeForMinOrder,
    customerAddress,
    minOrderData,
  } = useSelector((state) => {
    return {
      subTotal: state.cart.subTotal,
      checkMinimum: state.deliveryChargeDTL.checkMinimum.data,
      loadingGetMinimumOrder: state.deliveryChargeDTL.checkMinimum.loading,
      orderTypeForMinOrder: state.orderMST.orderType,
      customerAddress: state.orderMST.customerAddress.data,
      minOrderData: state.deliveryChargeDTL.checkMinimum.data,
    };
  });


  const outletAddress = useSelector((state) => state.outletMST.currentOutlet.data?.[0]?.outletName);

  useEffect(() => {
    setMinOrderValue(makeMinOrderValueToTwoDecimal(checkMinimum));
    // if (checkMinimum) {
    //   let  minimumOrderValue  = checkMinimum;
    //   setMinOrderValue(makeMinOrderValueToTwoDecimal(minimumOrderValue));
    // } else {
    //   if (customerAddress?.suburbId) {
    //     if (!minOrderData && !orderTypeForMinOrder.data == "OT01") {
    //       if (customerAddress?.suburbId) {
    //         dispatch(
    //           deliveryChargesDTLActions.getMinimumOrderValue(
    //             customerAddress?.suburbId
    //           )
    //         ).then((res) => {
    //           let { minimumOrderValue } = res[0];
    //           setMinOrderValue(
    //             makeMinOrderValueToTwoDecimal(minimumOrderValue)
    //           );
    //         });
    //       }
    //     }
    //   }
    // }
  }, [checkMinimum]);
  const outletShiftDetail = useSelector(
    (state) => state.outletShift.outletShiftDtl.data
  );
  const geographyDetail = useSelector(
    (state) => state.geographyMST.geography.data
  );

  useEffect(() => {
    setCartTrackError(null);
    if (!outletShiftDetail) {
      // dispatch(outletShiftActions.getOutletShiftDTL());
    }
    // if (!geographyDetail) {
    //   // dispatch(geographyMSTActions.getAll());
    // }
    // dispatch(cartActions.syncCartTracking(CART_TRACKING_PAGES["check-out"]));
  }, []);
  useEffect(() => {
    if (!loadingGetMinimumOrder) {
      if (subTotal >= minOrderValue || orderTypeForMinOrder.data == "OT01") {
        setCartTrackError(null);
      } else {
        setCartTrackError(
          <>
            <span>
              You'll need to add more to your cart. Minimum Delivery is{" "}
            </span>
            <span style={{ fontWeight: "bold" }}>${minOrderValue}</span>{" "}
          </>
        );
      }
    }
  }, [subTotal, minOrderValue, loadingGetMinimumOrder, orderTypeForMinOrder]);

  // TEST

  const dispatch = useDispatch();
  const { items } = useSelector((s) => s.cart);
  const showPopUpCart = useSelector((s) => s.cart.showPopUpCart);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const [active, setActive] = useState(false);
  const data = useSelector((state) => state.auth.authUser);
  const custData = useSelector((state) => state.auth.authCust);
  const tempData = useSelector((state) => state.orderMST?.tempUser);
  const orderLoading = useSelector(
    (state) => state.orderMST?.orderTime?.loading
  );
  const location = useLocation();
  const history = useHistory();
  const [activeWidth, setActiveWidth] = useState(true);
  const [cartCount, setCartCount] = useState(0);

  const isActive = (path) =>
    matchPath(location.pathname, { path })?.isExact === true;
  const openUserProfileDialog = (type) => {
    if (type === "sign-in") {
      setOpenSignIn(true);
    } else if (type === "sign-up") {
      setOpenSignUp(true);
    } else if (type === "forgot") {
      setOpenForgotPass(true);
    }
  };
  const closeUserProfileDialog = (type) => {
    if (type === "sign-in") {
      setOpenSignIn(false);
    } else if (type === "sign-up") {
      setOpenSignUp(false);
    } else if (type === "forgot") {
      setOpenForgotPass(false);
    }
  };

  const activeclass = () => {
    setActive(!active);
    dispatch(cartSlice.actions.setActiveNavbar(active));
  };

  useEffect(() => {
    if (!active) {
      setActive(true);
    }
    if (location.pathname === "/") {
      setActiveWidth(true);
    } else {
      setActiveWidth(false);
    }
  }, [location]);

  const authSuccess =
    data === null
      ? false
      : data.ipAddress !== data.userName && data.signUp === true
      ? true
      : false;

  useEffect(() => {
    if (!Array.isArray(items)) return;
    let sum = 0;
    items.forEach((each) => {
      sum += each.qty;
    });
    return setCartCount(sum);
  });

  const activeSwap = (gCode, name) => {
    let data = {
      name: name,
      gCode: gCode,
    };
    dispatch(
      cartActions.setOfferAction({ add: false, remove: false, swap: true })
    );
    dispatch(cartSlice.actions.setSwappingAction(true));
    sessionStorage.setItem("swap", JSON.stringify(true));
    dispatch(cartActions.setSwapAction(data));
  };

  const { currentUserTime } = useSelector((state) => {
    return {
      currentUserTime: state.cart.currentUserTime,
    };
  });

  return (
    <nav className="navbar navbar-expand-md navbar-light px-1 ">
      <div className="navForMobile">
        <button
          onClick={activeclass}
          className="navbar-toggler"
          type="button"
          data-toggle=""
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="searchCart-box">
          <div className="search-box" style={{ margin: "auto" }}>
            <div>
              {/* {!searchToggle && (
                <i
                  style={{ color: "black" }}
                  className="fas fa-search icon-left icons"
                  onClick={() => setSearchToggle(true)}
                ></i>
              )}
              {searchToggle && (
                <i
                  className="fas fa-times icons icon-left searchCrossIcon"
                  onClick={() => setSearchToggle(false)}
                ></i>
              )}

              {searchToggle && (
                <input
                  style={{ width: "180px", fontSize: "15px" }}
                  type="search"
                  className="search-input-navbar"
                  placeholder="Search"
                />
              )}

              {searchToggle && (
                <i className="fas fa-search icon-right icons"></i>
              )} */}
            </div>
          </div>
          <div className="cartBox ">
            {location.pathname.includes("payment") ? (
              <span
                style={{ opacity: "0.75", cursor: "context-menu" }}
                className="cart-icon"
              >
                <i className="fas fa-shopping-bag cartSize"></i>
                <span className="badgeMobile badge">{cartCount}</span>
              </span>
            ) : (
              <span
                onClick={
                  orderLoading
                    ? {}
                    : () => dispatch(cartActions.setShowPopUpCart(true))
                }
                style={showPopUpCart ? { color: "var(--test-primary)" } : {}}
                className="cart-icon"
              >
                <i className="fas fa-shopping-bag cartSize"></i>
                <span className="badgeMobile badge">{cartCount}</span>
              </span>
            )}
            <CustomDialog
              isOpen={showPopUpCart}
              handleClose={() => dispatch(cartActions.setShowPopUpCart(false))}
              dialogBody={
                <>
                  <div>
                    {
                      // activeSubpage === "check-out" || activeSubpage === "payment" ?
                      // loadingGetMinimumOrder ? <Spinner/> :
                      cartTrackError ? (
                        <div className="minimum mt-2 ">
                          <div>{cartTrackError}</div>
                        </div>
                      ) : null
                      // : null
                    }
                  </div>
                  <Cart
                    activeSwap={activeSwap}
                    onEditBtnClick={(cartItem, type) => {
                      if (type === "builtPizza") {
                        dispatch(
                          cartSlice.actions.setLocalIdForBuildYourOwn(
                            cartItem.localId
                          )
                        );
                        dispatch(buildYourOwnActions.loadItem(cartItem));
                        history.push("/menu/G1/build-your-own-pizza");
                        dispatch(cartActions.setShowPopUpCart(false));
                      }
                      if (type === "customize") {
                        dispatch(customizeActions.loadItem(cartItem));
                        history.push("/menu/G1/customize");
                        dispatch(cartActions.setShowPopUpCart(false));
                      } else if (type === "hnh") {
                        dispatch(hnhSlice.actions.setLocalId(cartItem.localId));
                        dispatch(hnhActions.loadItem(cartItem));
                        history.push("/menu/G1/half-and-half");
                        dispatch(cartActions.setShowPopUpCart(false));
                      }
                    }}
                    nextBtn={(function () {
                      return {
                        handler: () => {
                          dispatch(cartActions.setShowPopUpCart(false));
                        },
                        text: (
                          <Link
                            to="/menu/check-out/check-out"
                            style={{ color: "#fff" }}
                          >
                            CHECKOUT
                          </Link>
                        ),
                      };
                    })()}
                  />
                </>
              }
              dialogTitle="Cart"
              closeButton={true}
              dialogHeader
              dialogClassName="p-0 "
            />
          </div>
        </div>
      </div>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
        style={active ? { display: "none" } : { display: "block" }}
      >
        <ul
          className={
            activeWidth
              ? "navbar-nav mr-auto marginWithIcon"
              : "navbar-nav mr-auto marginWithouticon"
          }
        >
          <li
            className={`nav-item ${
              isActive(ROUTES.home._path) ? "active" : ""
            }`}
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                if (cartCount && cartCount > 0) {
                  let currentSystemTime = new Date().getTime();
                  let diff = currentSystemTime - currentUserTime;
                  diff = diff / 1000;
                  diff = Math.floor(diff);
                  if (diff > defaultTimeDiffForClearingCart) {
                    setActive(!active);
                    history.push(ROUTES.home._path);
                  } else {
                    setActive(!active);
                    history.push(ROUTES.home._path);
                  }

                  //   if (
                  //     window.alert(
                  //       "This will lose your data from cart. Do you want to continue?"
                  //     )
                  //   ) {
                  //     dispatch(cartActions.destroyOrderAndClear());
                  //     setActive(!active);
                  //     history.push(ROUTES.home._path);
                  //   }
                  // } else {
                  //   setActive(!active);
                  //   history.push(ROUTES.home._path);
                  // }
                } else {
                  setActive(!active);
                  history.push(ROUTES.home._path);
                }
              }}
              className="nav-link"
              to={ROUTES.home._path}
            >
              Home
            </Link>
          </li>
          <li
            className={`nav-item ${
              isActive(ROUTES.menu._pathWithParams) ? "active" : ""
            }`}
          >
            <Link
              onClick={activeclass}
              className="nav-link"
              to={{ pathname: "/menu", state: { isDirect: false } }}
              // to={{ pathname: "/menu/G2/default", state: { isDirect: false } }}
            >
              Menu
            </Link>
          </li>
          <li
            className={`nav-item ${
              isActive(ROUTES.offers._path) ? "active" : ""
            }`}
          >
            <Link
              onClick={activeclass}
              className="nav-link"
              to={ROUTES.offers._path}
            >
              Offers
            </Link>
          </li>
          <li
            className={`nav-item ${
              isActive(ROUTES.about._path) ? "active" : ""
            }`}
          >
            <Link
              onClick={activeclass}
              className="nav-link"
              to={ROUTES.about._path}
            >
              Contact US
            </Link>
          </li>
        </ul>



        <div className="outletInfo">
        <svg  width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#262626" stroke-width="1.5"/>
<path d="M3.6202 8.49C5.5902 -0.169998 18.4202 -0.159997 20.3802 8.5C21.5302 13.58 18.3702 17.88 15.6002 20.54C13.5902 22.48 10.4102 22.48 8.3902 20.54C5.6302 17.88 2.4702 13.57 3.6202 8.49Z" stroke="#262626" stroke-width="1.5"/>
</svg>

                <span>{outletAddress}</span>

              </div>

        {authSuccess ? (
          <span className="user-container">
            Welcome{" "}
            {custData?.customerFirstName + " " + custData?.customerLastName}
            <UserAccountPopUp />
          </span>
        ) : (
          <span
            className="user-container"
            onClick={() => openUserProfileDialog("sign-in")}
          >
            Sign-In
            <button className="user-btn">
              <i className="fas fa-user-circle"></i>
            </button>
          </span>
        )}

        <SignIn
          open={openSignIn}
          closeDialog={closeUserProfileDialog}
          openDialog={openUserProfileDialog}
        />
        <SignUp
          open={openSignUp}
          closeDialog={closeUserProfileDialog}
          openDialog={openUserProfileDialog}
          signUpdata={tempData?.data}
        />
        <ForgotPassword
          open={openForgotPass}
          closeDialog={closeUserProfileDialog}
          openDialog={openUserProfileDialog}
        />
      </div>
    </nav>
  );
};

export default Navbar;
