import { createSlice } from "@reduxjs/toolkit";
import feedbackCrud from '../../_apis/cruds/feedbackCrud'
const REDUCER_NAME = "feedbackMST"
const TYPES = {
    FEEDBACK: "feedback",
    SITE_FEEDBACK: "siteFeedback",  
  };
  export const feedbackMSTReduxSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
      ...Object.values(TYPES).reduce((occ, cur) => {
        return { ...occ, [cur]: { loading: false, error: null, data: null } };
      }, {}),
    },
    reducers: {
      catchError: (state, action) => {
        state[action.payload.type].loading = false;
        state[action.payload.type].error = action.payload.error;
      },
      startCall: (state, action) => {
        state[action.payload.type].error = null;
        state[action.payload.type].loading = true;
      },
      stopCall: (state, action) => {
        state[action.payload.type].error = null;
        state[action.payload.type].loading = false;
      },
      dataRecieved: (state, action) => {
        state[action.payload.type].error = null;
        state[action.payload.type].loading = false;
        state[action.payload.type].data = action.payload.data;
      },
    },
  });
  
  const startCall = (type) => feedbackMSTReduxSlice.actions.startCall({ type });
  const stopCall = (type) => feedbackMSTReduxSlice.actions.stopCall({ type });
  const dataRecieved = (type, data) =>
  feedbackMSTReduxSlice.actions.dataRecieved({ type, data });
  const catchError = (type, error) =>
  feedbackMSTReduxSlice.actions.catchError({ type, error });

  export const feedbackMSTActions = {
      submit:(rating,orderId,feedback)=>(dispatch)=>{
        dispatch(startCall(TYPES.FEEDBACK));
        let data={
          param:{},
          body:{
          feedBack:feedback,
          orderMSTId:orderId,
          rating:rating,}
        }
        return feedbackCrud
          .submitFeedBack(data)
          .then((res) => {
            dispatch(dataRecieved(TYPES.FEEDBACK, res));
          })
          .catch((err) => {
            dispatch(catchError(TYPES.FEEDBACK, err));
          });
      },
      siteFeedbackSubmit:(rating,qualityRating,feedBack,custId)=>(dispatch)=>{
        dispatch(startCall(TYPES.SITE_FEEDBACK));
        let data={
          param:{},
          body:{rating:rating,
          qualityRating:qualityRating,
          feedBack:feedBack,
          customerMSTId:custId}}
        return feedbackCrud
          .siteFeedBack(data)
          .then((res) => {
            dispatch(dataRecieved(TYPES.SITE_FEEDBACK, res));
          })
          .catch((err) => {
            dispatch(catchError(TYPES.SITE_FEEDBACK, err));
          });
      }
  }
  