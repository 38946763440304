import { Suspense, useEffect, useState } from "react";
import SplashScreen from "./componets/splashScreen/SplashScreen";
import { Provider } from "react-redux";
import { persistor, store } from "./_redux/store";
import Layout from "./componets/layout/Layout";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

function App() {


  document.documentElement.style.setProperty('--test-primary', process.env.REACT_APP_BRANDING_COLOR);
  document.documentElement.style.setProperty('--test-secondary', process.env.REACT_APP_OUTLET_LIGHT);
  document.documentElement.style.setProperty('--logo-top-height', process.env.REACT_APP_TOPBAR_TOP);
  document.documentElement.style.setProperty('--logo-top-width', process.env.REACT_APP_TOPBAR__LOGO_WIDTH);
  document.documentElement.style.setProperty('--logo-top-right', process.env.REACT_APP_TOPBAR__LOGO_RIGHT); 


  document.documentElement.style.setProperty('--logo-out-sel-height', process.env.RREACT_APP_OUT_SEL_LOGO_MOBILE_HEIGHT); 
  document.documentElement.style.setProperty('--logo-out-sel-width', process.env.RREACT_APP_OUT_SEL_LOGO_MOBILE_WIDTH); 





  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="App">
          <Suspense fallback={<SplashScreen />}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Layout />
            </BrowserRouter>
          </Suspense>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
