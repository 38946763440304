import React, { useEffect, useState } from "react";
import LeftImg from "../../assets/images/leftImg.jpg";
import RightImg from "../../assets/images/rightIMG.jpg";
// import LogoImage from "../../assets/images/franky_logo_Curve (1)(1).png";

import "./SelectOutlet.scss";
import OrderPreference from "../home/OrderPreference";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../_redux/files/authRedux";
import { deliveryChargesDTLActions } from "../../_redux/files/deliveryChargeRedux";
import { outletMSTActions } from "../../_redux/files/outletMSTRedux";
import { homeSlice } from "../../_redux/files/homeRedux";
import { menuPageSlice } from "../../_redux/files/menuPageRedux";
import { outletShiftDTLSlice } from "../../_redux/files/outletShiftRedux";
import SignIn from "../userAccount/SignIn";
import SignUp from "../userAccount/SignUp";
import ForgotPassword from "../userAccount/ForgotPassword";
import UserAccountPopUp from "../userAccount/UserAccountPopUp";
import SplashScreen from "../../componets/splashScreen/SplashScreen";

const SelectOutlet = () => {



  const dispatch = useDispatch();
  const { authUser, isAuthenticating, error, outletLoading } = useSelector(({ auth }) => ({
    authUser: auth.authUser,
    isAuthenticating: auth.loading.authenticating,
    error: auth.error.authenticating,
    outletLoading:auth.loading.signinOutlet

  }));
  
  const storeName = process.env.REACT_APP_WEB

  const LogoImage = (require(process.env.REACT_APP_LOGO_IMAGE_PATH).default);


  useEffect(() => {
    // dispatch(authActions.createUserIp()).then((res) => {

    //   dispatch(homeSlice.actions.clearMenuItems())
    //   dispatch(menuPageSlice.actions.clearAllItems())
    //   dispatch(outletShiftDTLSlice.actions.clearShift())
    //   // clear data
    //   dispatch(deliveryChargesDTLActions.fetchPolygon())
    //   dispatch(outletMSTActions.getAllOutlet())
    // })

    console.log(
      "auth user and is auth-loading state",
      authUser,
      isAuthenticating
    );
    if (!authUser && !isAuthenticating) {
      dispatch(authActions.authenticateUser())
        .then(() => {
          dispatch(deliveryChargesDTLActions.fetchPolygon());
          dispatch(outletMSTActions.getAllOutlet());
        })
        .catch((err) => {
          dispatch(deliveryChargesDTLActions.fetchPolygon());
          dispatch(outletMSTActions.getAllOutlet());
        });
    }
  }, []);

  const data = useSelector((state) => state.auth.authUser);
  const custData = useSelector((state) => state.auth.authCust);
  const tempData = useSelector((state) => state.orderMST?.tempUser);

  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const authSuccess =
    data === null
      ? false
      : data.ipAddress !== data.userName && data.signUp === true
      ? true
      : false;
  const closeUserProfileDialog = (type) => {
    if (type === "sign-in") {
      setOpenSignIn(false);
    } else if (type === "sign-up") {
      setOpenSignUp(false);
    } else if (type === "forgot") {
      setOpenForgotPass(false);
    }
  };

  const openUserProfileDialog = (type) => {
    if (type === "sign-in") {
      setOpenSignIn(true);
    } else if (type === "sign-up") {
      setOpenSignUp(true);
    } else if (type === "forgot") {
      setOpenForgotPass(true);
    }
  };

  return (
    <>
      {/* </div> */}

      {
    // outletLoading ||
     isAuthenticating || error ? (
      <SplashScreen
      loading={
        // outletLoading ||
         isAuthenticating}
      text={
        error ? (
          <small className="text-danger">
            {error.message}, Please refresh.
          </small>
        ) : (
          "Please Wait..."
        )
      }
    />
  ) : 
   <div className="containerOutlet">
  <div className="row mt-2 mx-0">
    <div className="col-5"></div>
    <div className="d-flex col-7 align-items-end justify-content-end">
      <div className="signIn">
        {authSuccess ? (
          <span className="user-container">
            Welcome{" "}
            {custData?.customerFirstName +
              " " +
              custData?.customerLastName}
            <UserAccountPopUp />
          </span>
        ) : (
          <span
            className="user-container"
            onClick={() => openUserProfileDialog("sign-in")}
          >
            Sign-In
            <button className="user-btn">
              <i className="fas fa-user-circle"></i>
            </button>
          </span>
        )}

        <SignIn
          open={openSignIn}
          closeDialog={closeUserProfileDialog}
          openDialog={openUserProfileDialog}
        />
        <SignUp
          open={openSignUp}
          closeDialog={closeUserProfileDialog}
          openDialog={openUserProfileDialog}
          signUpdata={tempData?.data}
        />
        <ForgotPassword
          open={openForgotPass}
          closeDialog={closeUserProfileDialog}
          openDialog={openUserProfileDialog}
        />
      </div>
    </div>
  </div>



  {/* <div className="col-12"> */}
  <div
    className="row mx-0"
    style={{ minHeight: "100vh", marginTop: "2rem"  }}
  >
    <div className="col-3">
      <img className="imgRight" src={RightImg} alt="right" />
    </div>
    <div className="col-6 orderTypeContainer">
      <div className="row justify-content-center ">
          <div className="align-items-center logo-container">
            <img
              className="logoMain"
              src={LogoImage}
              alt="pizza logo"
            />
          </div>
      </div>
      <div
        className="row justify-content-center "
        style={{ alignSelf: "start" }}
      >
        <div className="col-12 mt-3">
          <h2 className="outletHeader" style={{color:  "var(--test-primary)",}}>Welcome to  
            <span style={{fontSize: '2.3rem'}}> {storeName}</span></h2>
        </div>
        <div className="col-12">
        </div>
        <div className="col-10 outletOrderPref">
          <OrderPreference />
        </div>
      </div>
    </div>
    <div className="col-3 p-0 ">
      <img className="imgLeft" src={LeftImg} alt="left" />
    </div>
  </div>
</div>
}


     
      {/* </div> */}
    </>
  );
};

export default SelectOutlet;
