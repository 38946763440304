
import axios from 'axios'
import { errorMessageFormatter } from '../../helpers/Utils'
const homeControllerCruds = {

    getMenu : () => {
        return axios.get(`/homeController/menus`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOffer : () => {
        // return axios.get(`/homeController/offers`)
        return axios.get(`/recipeData/byGroupCode/data`)
            .then(res => res.data.status ?
                //   console.log(res.data.data)
                   Promise.resolve(JSON.parse(res.data.data)) 
                 : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOfferById : ({ id }) => {
        return axios.get(`/homeController/offers/id/${id}`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOfferPaginated : () => {
        return axios.get(`/homeController/offers/paginated/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getOfferInfo : () => {
        return axios.get(`/homeController/offersinfo/`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
    getPopularPizza : () => {
        return axios.get(`/homeController/popular`)
            .then(res => res.data.status ? Promise.resolve(res.data.data) : Promise.reject({message:errorMessageFormatter(res.data),status:res.status}))
            .catch(err => Promise.reject({message:err.message,status:err?.response?.status}))
    },
        
}

export default homeControllerCruds;
    
