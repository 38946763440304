import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootreducer";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import dateValidationMiddleware from "./dateValidationMiddleware";

const persistConfig = {
    key: 'cart',
    storage: storage,
    whitelist: ['cart','orderMST'], // which reducer want to store,
};

const pReducers = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: pReducers,
    middleware: [
        ...getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true
        }),
        dateValidationMiddleware 
    ],
    devTools: process.env.NODE_ENV !== "production",
})

export const persistor = persistStore(store);