
import { createSlice } from "@reduxjs/toolkit";
import checkOutPaymentCruds from '../../_apis/cruds/checkOutPaymentCrud'
const REDUCER_NAME = "checkOut"
const TYPES = {
    PAYMENT: 'payment',
}

export const checkoutSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
        ...Object.values(TYPES).reduce((occ, cur) => { return ({ ...occ, [cur]: { loading: false, error: null, data: null } }) }, {})
    },
    reducers: {
        catchError: (state, action) => {
            state[action.payload.type].loading = false;
            state[action.payload.type].error = action.payload.error;
        },
        startCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = true;
        },
        stopCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
        },
        dataRecieved: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
            state[action.payload.type].data = action.payload.data;
        },
    },
})

const startCall = (type) => checkoutSlice.actions.startCall({ type })
const stopCall = (type) => checkoutSlice.actions.stopCall({ type })
const dataRecieved = (type, data) => checkoutSlice.actions.dataRecieved({ type, data })
const catchError = (type, error) => checkoutSlice.actions.catchError({ type, error })
export const checkoutActions = {
    pay: (orderAmount,orderId,paymentMethod) => dispatch => {
        dispatch(startCall(TYPES.PAYMENT))
        let data={param:{},body:{orderAmount:orderAmount, orderId:orderId, paymentMethod:paymentMethod}}
        return checkOutPaymentCruds.createPayment(data).then(res => {
            dispatch(dataRecieved(TYPES.PAYMENT, res))
            return Promise.resolve(res);
        }).catch(err => {
            dispatch(catchError(TYPES.PAYMENT, err))
            return Promise.reject(err);
        })
    }
}