import { createSlice } from "@reduxjs/toolkit";

import swal from "sweetalert";
import { defaultTimeDiffForClearingCart, letsRound } from "../../helpers/Utils";

import {
  formatDate,
  formatTime,
  letsFloor,
  formatSelectedTime,
  resolveAfter2Seconds,
  wait,
  errorMessageFormatter,
} from "../../helpers/Utils";
import cartTrackingMSTCruds from "../../_apis/cruds/cartTrackingMSTCrud";
import couponMSTCruds from "../../_apis/cruds/couponMSTCrud";
import itemMSTCrud from "../../_apis/cruds/itemMSTCrud";
import orderMSTCruds from "../../_apis/cruds/orderMSTCrud";
import orderDTLCruds from "../../_apis/cruds/orderDTLCrud";
import surchargeDTLCruds from "../../_apis/cruds/surchargeDTLCrud";
import {
  getItemFromOffer,
  replaceItemAtIndex,
  getAvailableIndex,
  addedGroupItem,
  getSingleItem,
} from "../../helpers/OfferHelper";
import { GetActivePageName } from "../../helpers/Utils";
import ROUTES from "../../constants/ROUTES";
import { orderMSTActions } from "./orderMSTRedux";
import Swal from "sweetalert2";
const REDUCER_NAME = "cart";
const axios = require("axios");
export const calcSurchargeAmt = (subTotal, surObj) => {
  if (surObj.amountUnit === "%") {
    return letsFloor((surObj.amount * subTotal) / 100);
  } else {
    return letsFloor(surObj.amount);
  }
};

export const calcToppings = (Toppings) => {
  // console.log(Toppings)
  // console.log('calc Toppings calling');
  return {
    totalToppings: Toppings.filter((x) => x.itemQuantity).length,
    toppingsPrice: letsFloor(
      Toppings.filter((x) => x.itemQuantity - x.defaultQuantity > 0).reduce(
        (a, b) => a + b.addCost * (b.itemQuantity - b.defaultQuantity),
        0
      )
    ),
    totalQtyOfToppings: Toppings.filter((x) => x.itemQuantity).reduce(
      (occ, cur) => occ + parseInt(cur.itemQuantity ?? 0),
      0
    ),
  };
};
export const calcBuildYourPizzaToppings = (Toppings) => {
  let totalFreeToppings = 4; // additional 4 toppping free
  let toppingsPrice = 0;
  Toppings.forEach((t, i) => {
    if (t.itemQuantity - t.defaultQuantity > 0) {
      for (let tQty = 0; tQty < t.itemQuantity - t.defaultQuantity; tQty++) {
        if (totalFreeToppings > 0) {
          totalFreeToppings--;
        } else {
          toppingsPrice = toppingsPrice + t.addCost;
        }
      }
    }
  });
  return {
    totalToppings: Toppings.filter((x) => x.itemQuantity).length,
    toppingsPrice: letsFloor(toppingsPrice),
    totalQtyOfToppings: Toppings.filter((x) => x.itemQuantity).reduce(
      (occ, cur) => occ + parseInt(cur.itemQuantity ?? 0),
      0
    ),
  };
};

export const getTotalOfSurcharges = (subTotal, surcharges) => {
  return surcharges
    ?.filter((x) => !x.halfNHalf)
    .map((x) => calcSurchargeAmt(subTotal, x))
    ?.reduce((occ, cur) => cur + letsFloor(occ), 0);
};

const calcSubTotals = ({ items, voucher, deliveryCharge, surcharges }) => {
  let amount = 0;
  surcharges.forEach((item) => {
    amount = item.amount;
  });
  let subTotal = 0;
  let savedAmount = 0;
  let additionalPrice = 0;
  items.forEach((item) => {
    if (item.isHnh) {
      subTotal += letsFloor(
        (item.left.price +
          item.left.toppingsPrice +
          item.left.selectedBasePrice +
          item.right.price +
          item.right.toppingsPrice +
          item.right.selectedBasePrice +
          item.hnhSurchargeAmt) *
          item.qty
      );
    } else if (item.offeritem) {
      let toppingPrice = 0;
      let additionalPrice = 0;
      item.offeritem.map((x) => {
        toppingPrice += x.toppingsPrice ?? 0;
        additionalPrice += x.additionalPrice ?? 0;
        additionalPrice += x.selectedBasePrice ?? 0;
      });

      subTotal += letsFloor(
        (letsFloor(item.price) +
          letsFloor(toppingPrice) +
          letsFloor(additionalPrice)) *
          item.qty
      );
    }
    // subTotal += (
    //   ((item.price) +
    //     letsFloor(item.toppingsPrice) +
    //     letsFloor(item.selectedBasePrice) +
    //     letsFloor(item.selectedSaucePrice ?? 0)) *
    //     item.qty
    // );
    else
      subTotal +=
        (parseFloat(letsRound(item.price)) +
          parseFloat(letsRound(item.toppingsPrice)) +
          parseFloat(letsRound(item.selectedBasePrice)) +
          parseFloat(letsRound(item.selectedSaucePrice ?? 0))) *
        item.qty;

      subTotal = parseFloat(subTotal.toFixed(2));
    // console.log(subTotal);
  });
  if (voucher.appliedOn && voucher.appliedVoucher) {
    if (voucher.appliedOn === -1) {
      if (voucher.appliedVoucher.couponType === "AMOUNT") {
        savedAmount = voucher.appliedVoucher.couponValue;
      } else if (voucher.appliedVoucher.couponType === "PERCENTAGE") {
        savedAmount =
          ((subTotal + deliveryCharge) /* +(amount ?? 0) */ *
            voucher.appliedVoucher.couponValue) /
          100;
      }
    }
    if (
      voucher.appliedOn > 0 &&
      items.map((x) => x.localId).includes(voucher.appliedOn)
    ) {
      savedAmount = voucher.appliedVoucher.couponValue;
    }
  }
  return {
    subTotal: subTotal - letsFloor(savedAmount) + (deliveryCharge ?? 0),
    savedAmount: letsFloor(savedAmount),
    additionalPrice,
  };
};

const deleteAppliedVoucher = (state) => {
  if (state.voucher) {
    state.voucherCode = null;
    state.voucher.appliedOn = 0;
    state.voucher.appliedVoucher = null;
    state.voucher.error = null;
    state.voucher.loading = false;
  }
  return state;
};
const initialState = {
  fromOrderStage: false,
  showPopUpCart: false,
  fromSessionExpire: false,
  paymentMethodClicked: false,
  orderMSTObj: null,
  placedOrderMSTObj: null,
  orderCreateLoading: false,
  error: null,
  minimumOrderValue: 0,
  items: [],
  sizeId: null,
  totalItems: 0,
  lastGeneratedId: 0,
  subTotal: 0,
  paymentModeCharges: 0,
  savedAmount: 0,
  voucher: { loading: false, appliedVoucher: null, error: null, appliedOn: 0 },
  voucherCode: "",
  surcharges: [],
  currentUserTime: null,
  navigateToHome: false,
  loading: false,
  loadingCheckMinOrder: false,
  loadingUpdateStage: false,
  paymentObj: null,
  cartTrackingMSTId: null,
  paypalOrderId:null,
  paypalOrderTotal:null,
  swap: false,
  add: false,
  offerItemIndex: 0,
  offerId: 0,
  editGroup: "",
  offerLocalId: 0,
  loadingOfferAction: { add: false, remove: false, swap: false },
  deliveryCharge: 0,
  otherInstrucation: "",
  deliveyInstrucation: "",
  swapData: "",
  combSeqForSwap: null,
  swappingAction: null,
  localIdForBuildYourOwn: null,
  localIdForCustomize: null,
  orderCreated: null,
  activeNavbar: false,
  orderFromReorder: false,
};
export const cartSlice = createSlice({
  name: REDUCER_NAME,
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    startCall: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    setLocalIdForBuildYourOwn: (state, action) => {
      state.localIdForBuildYourOwn = action.payload;
    },
    setSizeId: (state, action) => {
      state.sizeId = action.payload;
    },
    setOrderFromReorder: (state, action) => {
      state.orderFromReorder = action.payload;
    },
    setFromOrderStage: (state, action) => {
      state.fromOrderStage = action.payload;
    },
    setFromSessionExpire: (state, action) => {
      state.fromSessionExpire = action.payload;
    },
    setPaypalOrderId: (state, action) => {
      state.paypalOrderId = action.payload;
    },
    setPaypalOrderTotal: (state, action) => {
      state.paypalOrderTotal = action.payload;
    },
    setSwappingAction: (state, action) => {
      state.swappingAction = action.payload;
    },
    setActiveNavbar: (state, action) => {
      state.activeNavbar = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload;
    },
    setPaymentMethodClicked: (state, action) => {
      state.paymentMethodClicked = action.payload;
    },
    startCallForUpdateStage: (state, action) => {
      state.loadingUpdateStage = true;
    },
    stopCallForUpdateStage: (state, action) => {
      state.loadingUpdateStage = false;
    },
    startCallForCheckMinOrder: (state, action) => {
      state.loadingCheckMinOrder = true;
    },
    stopCallForCheckMinOrder: (state, action) => {
      state.loadingCheckMinOrder = false;
    },
    stopCall: (state, action) => {
      state.loading = false;
    },
    offerAction: (state, action) => {
      state.loadingOfferAction = action.payload;
    },
    setCurrentuserTime: (state, action) => {
      state.currentUserTime = action.payload;
    },
    setOrderCreated: (state, action) => {
      state.orderCreated = action.payload;
    },
    setCombSeq: (state, action) => {
      state.combSeqForSwap = action.payload;
    },
    setNavigateToHome: (state, action) => {
      state.navigateToHome = action.payload;
    },
    swapAction: (state, action) => {
      state.swapData = action.payload;
    },
    addOffer: (state, action) => {
      state.lastGeneratedId++;
      state.items.push({ ...action.payload, localId: state.lastGeneratedId });
      state.totalItems = state.items.length;
      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    addItem: (state, action) => {
      state.lastGeneratedId++;
      if (action.payload?.offeritem) {
        let itemArr = [];
        action.payload?.offeritem.map((row) => {
          itemArr.push({
            ...row,
            localId: state.lastGeneratedId.toString(),
          });
          state.lastGeneratedId++;
        });
        action.payload.offeritem = itemArr;
      }
      state.items.push({
        ...action.payload,
        localId: state.lastGeneratedId.toString(),
      });
      state.totalItems = state.items.length;
      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    updateCartItem: (state, action) => {
      state.items = action.payload;
      state.totalItems = state.items.length;
      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    setQty: (state, action) => {
      state.items = state.items.map((z) => {
        if (z.localId === action.payload.localId) {
          z.qty = action.payload.qty;
        }
        return z;
      });

      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    addRemovedItemGroup: (state, action) => {
      state.items = state.items.map((data) => {
        if (
          data.offeritem &&
          data.id === action.payload.id &&
          data.combinationSequence === action.payload.combSeq
        ) {
          return {
            ...data,
            removedItemGroup: action.payload.arr,
          };
        } else {
          return data;
        }
      });
    },
    deleteOfferItem: (state, action) => {
      state.items = state.items.map((data) => {
        if (
          data.offeritem &&
          data.id === action.payload.id &&
          data.combinationSequence === action.payload.combSeq
        ) {
          return {
            ...data,
            offeritem: data.offeritem.filter(
              (el, i) => el.index !== action.payload.localId
            ),
          };
        } else {
          return data;
        }
      });
    },
    addSwapItem: (state, action) => {
      getItemFromOffer();
    },

    // calTotalItem: (state, action) => {
    //   let leng = action.payload.data.offersAllItem.filter((el) => {
    //     return el.default === true;
    //   });
    //   let lengItem = action.payload.data.offeritem;
    //   let diff = leng.length - lengItem.length + 1;

    //   state.items = state.items.map((data) => {
    //     if (data.base === action.payload.data.base) {
    //       return {
    //         ...data,
    //         numberOfitems: diff,
    //       };
    //     } else {
    //       return data;
    //     }
    //   });
    // },

    deleteItem: (state, action) => {
      state.items = state.items.filter(
        (x) => x.localId !== action.payload.localId
      );
      state.totalItems = state.items.length;
      if (action.payload.localId === state.voucher.appliedOn) {
        state = deleteAppliedVoucher(state);
      }
      if (state.voucher.appliedOn === -1 && state.items.length === 0)
        state = deleteAppliedVoucher(state);
      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
      state.offerItemIndex = -1;
      state.offerId = 0;
      state.editGroup = "";
      state.swap = false;
      state.add = false;
    },
    setQtyOfTopping: (state, action) => {
      state.items = state.items.map((z) => {
        if (z.localId === action.payload.localId) {
          if (z.isHnh) {
            z[action.payload.side].recipeObj.Toppings = z[
              action.payload.side
            ].recipeObj.Toppings.map((topp) => {
              if (topp.id === action.payload.toppingId)
                topp.itemQuantity = action.payload.qty;
              return topp;
            });
            const { toppingsPrice, totalToppings } = calcToppings(
              z[action.payload.side].recipeObj.Toppings
            );
            z[action.payload.side].totalToppings = totalToppings;
            z[action.payload.side].toppingsPrice = toppingsPrice / 2;
          } else if (z.offeritem) {
            z.offeritem = z.offeritem.map((x) => {
              if (x.index === action.payload.index) {
                x.recipeObj.Toppings = x.recipeObj.Toppings.map((topp) => {
                  if (topp.id === action.payload.toppingId)
                    topp.itemQuantity = action.payload.qty;
                  return topp;
                });
                const { toppingsPrice, totalToppings } = x.builtPizza
                  ? calcBuildYourPizzaToppings(x.recipeObj.Toppings)
                  : calcToppings(x.recipeObj.Toppings);
                x.totalToppings = totalToppings;
                x.toppingsPrice = toppingsPrice;
              }
              return x;
            });
          } else {
            z.recipeObj.Toppings = z.recipeObj.Toppings.map((topp) => {
              if (topp.id === action.payload.toppingId)
                topp.itemQuantity = action.payload.qty;
              return topp;
            });
            const { toppingsPrice, totalToppings } = z.builtPizza
              ? calcBuildYourPizzaToppings(z.recipeObj.Toppings)
              : calcToppings(z.recipeObj.Toppings);
            z.totalToppings = totalToppings;
            z.toppingsPrice = toppingsPrice;
          }
        }
        return z;
      });
      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    deleteTopping: (state, action) => {
      state.items = state.items.map((z) => {
        if (z.localId === action.payload.localId) {
          z.recipeObj.Toppings = z.recipeObj.Toppings.filter(
            (topp) => topp.id !== action.payload.toppingId
          );
          const { toppingsPrice, totalToppings } = z.builtPizza
            ? calcBuildYourPizzaToppings(z.recipeObj.Toppings)
            : calcToppings(z.recipeObj.Toppings);
          z.totalToppings = totalToppings;
          z.toppingsPrice = toppingsPrice;
        }
        return z;
      });

      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    replaceItem: (state, action) => {
      state.items = state.items.map((z) => {
        if (z.localId === action.payload.localId) {
          return action.payload;
        }
        return z;
      });
      const { subTotal, savedAmount } = calcSubTotals(state);
      state.subTotal = subTotal;
      state.savedAmount = savedAmount;
    },
    setVoucher: (state, action) => {
      if (state.voucher) {
        Object.keys(action.payload).forEach((k) => {
          state.voucher[k] = action.payload[k];
        });
        state.voucherCode = state.voucher.appliedVoucher?.couponCode ?? "";
        const { subTotal, savedAmount } = calcSubTotals(state);
        state.subTotal = subTotal;
        state.savedAmount = savedAmount;
      }
    },
    setVoucherCode: (state, action) => {
      state.voucherCode = action.payload;
    },
    setPaymentModeCharges: (state, {payload}) => {
      state.paymentModeCharges = payload;
    },
    setSurcharges: (state, { payload }) => {
      state.surcharges = payload;
    },
    setPaymentModeCharges: (state, {payload}) => {
      state.paymentModeCharges = payload;
    },
    setOrderMST: (state, { payload }) => {
      state.orderMSTObj = payload;
    },
    setState: (state, { payload }) => {
      Object.keys(payload).forEach((k) => {
        state[k] = payload[k];
      });
    },
  },
});

const prepareItemDtl = (itm, syncType, orderMSTState, itemSide = 0) => {
  let recipeDetail =
    itm.recipeObj?.Toppings?.map((topping, toppingSortOrder) => {
      return {
        id: null,
        recipeItemDTLId: topping?.id,
        qty: topping.itemQuantity,
        inFirstFour: topping.inFirstFour,
        defaultQty: topping.defaultQuantity,
        itemSide: itemSide,
        sortOrder: toppingSortOrder,
        base: false,
        active: true,
      };
    }) ?? [];
  if (itm.base) {
    const b = itm?.recipeObj?.Base.find((x) => x.id === parseInt(itm.base));
    if (b)
      recipeDetail.push({
        id: null,
        recipeItemDTLId: b?.id,
        qty: 1,
        defaultQty: b.defaultQuantity,
        itemSide: itemSide,
        sortOrder: recipeDetail.length,
        base: true,
        active: true,
      });
  }
  if (itm.sauce) {
    // Sauce code change because of multiple sauce showing in makeline
    //   const s = itm.sauce.id
    //     ? itm.sauce
    //     : itm.recipeObj.Sauce.find((x) => x.id == itm.sauce);
    //   recipeDetail.push({
    //     id: null,
    //     recipeItemDTLId: s.id,
    //     qty: s.itemQuantity,
    //     defaultQty: s.defaultQuantity,
    //     itemSide: itemSide,
    //     sortOrder: recipeDetail.length,
    //     base: false,
    //     active: true,
    //   });
    // }

    const sauceArray = [];

    const s = itm.sauce.id
      ? sauceArray.push([itm.sauce])
      : sauceArray.push(itm.recipeObj.Sauce);

    let val = itm.recipeObj.Sauce.find((x) => x.id == itm.sauce);

    for (let i = 0; i < sauceArray[0].length; i++) {
      let sauces = sauceArray[0][i];

      recipeDetail.push({
        id: null,
        recipeItemDTLId: sauces.id,
        qty: sauces.itemQuantity,
        defaultQty: sauces.defaultQuantity,
        itemSide: itemSide,
        sortOrder: recipeDetail.length,
        base: false,
        active: sauces.active
      });
    }
  }
  // recipeDetail = recipeDetail.filter(
  //   (detail) => detail.qty !== 0
  // );
  // }
  recipeDetail = recipeDetail.filter(
    (detail) => detail.qty !== 0 || detail.defaultQty !== 0
  );


  let comboId = itm.comboRefId ? itm.comboRefId : null;


  let dtl = {
    // id: itm.dbId && itm.dbId > 0 ? itm.dbId : null,
    id: itm.dbId && itm.dbId !== "" && !itm.freshOrder ? itm.dbId : null,
    itemMSTId: itm?.itemMSTId
      ? itm.itemMSTId
      : itm?.id
      ? itm?.id
      : itm?.itemObj?.id,
    recipeMSTId: itm.recipeObj?.id,
    itemSide: itemSide,
    comboDefaultQuantity: itm.quantity,
    // this change done because in offer qty is is as qunty solve that issue
    qty: itm.comboRefId ? itm.itemObj.qty : itm.qty,
    // qty: itm.comboRefId ? itm.quantity * itm.itemObj.qty : itm.qty,
    //
    // qty: itm.comboRefId ? itm.itemObj.qty : itm.qty,
    sortOrder: itm.localId,
    displayName: itm.name,
    cookingInstruction: null,
    additionalValue: itm.additionalPrice ?? 0,
    hnhSurcharge: itm.hnhSurchargeAmt ?? 0,
    // orderDTLRefId: itm.dbId && itm.dbId > 0 ? itm.dbId : itm.localId,
    orderDTLRefId:
      itm.dbId && itm.dbId !== "" && !itm.freshOrder ? itm.dbId : itm.localId,
    comboRefId: comboId,
    orderStage:
      syncType === SYNC_ORDER_TYPES.place
        ? orderMSTState.orderDateTime.data.timedOrder
          ? "DS02"
          : "DS03"
        : syncType === SYNC_ORDER_TYPES.destroy
        ? "DS12"
        : "DS01",
    active: true,
    orderRecipeItemWebRequestSet: recipeDetail,
    combSeq: itm.comboRefId ? itm.combSeq : null,
  };
  return dtl;
};

const prepareOrderMSTRequest = (getState, syncType) => {
  const allStates = getState();


  const orderMSTObj = allStates.cart.orderMSTObj;
  const authUser = allStates.auth.authUser;
  const authCust = allStates.auth.authCust;
  const guestCust = allStates.orderMST.guestCust?.data;
  const items = allStates.cart.items;
  const orderMSTState = allStates.orderMST;
  const paymentObj = allStates.cart.paymentObj;

  let orderDetail = [];
  items.forEach((itm) => {
    if (itm.isHnh) {
      const prepHalfPart = (side) => {
        return {
          ...side,
          qty: itm.qty,
          localId: itm.localId,
          hnhSurchargeAmt:
            itm.hnhSurchargeAmt > 0
              ? itm.hnhSurchargeAmt / 2
              : itm.hnhSurchargeAmt,
        };
      };
      orderDetail.push(
        prepareItemDtl(prepHalfPart(itm.left), syncType, orderMSTState, 1)
      );
      orderDetail.push(
        prepareItemDtl(prepHalfPart(itm.right), syncType, orderMSTState, 2)
      );
    } else if (itm.offeritem) {
      itm.offeritem.forEach((offerItem) => {
        const getOfferObj = (singleItem) => {
          return {
            ...singleItem,
            price: parseFloat(singleItem.price) / itm.offeritem.length,
            quantity: itm.qty,
          };
        };
        orderDetail.push(
          prepareItemDtl(getOfferObj(offerItem), syncType, orderMSTState)
        );
      });
    } else orderDetail.push(prepareItemDtl(itm, syncType, orderMSTState));
  });

  return {
    id: orderMSTObj?.id,
    userId: guestCust ? guestCust.userMSTId : authUser.userMSTId,
    remarks: process.env.REACT_APP_REMARKS,
    orderType: orderMSTState?.orderType?.data ?? "OT01",
    // timedOrder: syncType === SYNC_ORDER_TYPES.place ? orderMSTState.orderDateTime.data.timedOrder : null,
    // orderDate: syncType === SYNC_ORDER_TYPES.place ? formatDate(orderMSTState.orderDateTime.data.orderDate?new Date(orderMSTState.orderDateTime.data.orderDate):new Date(),"yyyy-mm-dd") ?? orderMSTObj?.orderDate : orderMSTObj?.orderDate ?? new Date(),
    // orderTime: syncType === SYNC_ORDER_TYPES.place ? orderMSTState.orderDateTime.data.orderTime === "now" ? formatTime(new Date()) : formatSelectedTime(orderMSTState.orderDateTime.data.orderTime) : null,
    timedOrder: orderMSTState?.orderDateTime?.data?.timedOrder
      ? orderMSTState.orderDateTime.data.timedOrder
      : null,
    orderDate:
      formatDate(
        orderMSTState?.orderDateTime?.data?.orderDate
          ? new Date(orderMSTState.orderDateTime.data.orderDate)
          : new Date(),
        "yyyy-mm-dd"
      ) ?? orderMSTObj?.orderDate,
    orderTime: orderMSTState?.orderDateTime?.data?.orderTime
      ? orderMSTState.orderDateTime.data.orderTime === "Now"
        ? syncType === SYNC_ORDER_TYPES.place
          ? formatTime(new Date())
          : null
        : formatSelectedTime(orderMSTState.orderDateTime.data.orderTime)
      : null,
    expressOrder: false,
    customerMSTId: guestCust
      ? guestCust.customerMSTId
      : authCust?.customerMSTId, //authUser.userMSTId,
    otherInstrucation: allStates.cart.otherInstrucation,
    deliveyInstrucation: allStates.cart.deliveyInstrucation,
    customerAddressDTL: {
      active: true,
      // address1: null, //"string",
      // address2: null, //"string",
      // customerAddressDTLId: null,
      // customerAddressTitle: null, //"string",
      // latitude: null,
      // longitude: null,
      customerMST: null,
      customerMSTId: null, //authUser.userMSTId,
      default: true,
      geoLocation: null, //"string",
      // "geographyMST1": null,
      // "geographyMST2": null,
      // "geographyMST3": null,
      // "geographyMST4": null,
      // "geographyMST5": null,
      // "geographyMST6": null,
      // "geographyMST7": null,
      // "geographyMST8": null,
      // "geographyMSTId1": 0,
      // "geographyMSTId2": 0,

      // geographyMSTId3: parseInt(
      //   allStates?.orderMST?.customerAddress?.data?.pincodeId
      // ),
      // geographyMSTId4: parseInt(
      //   allStates?.orderMST?.customerAddress?.data?.suburbId
      // ),
      // geographyMSTId5: parseInt(
      //   allStates?.orderMST?.customerAddress?.data?.streetNameId
      // ),
      // geographyMSTId6: parseInt(
      //   allStates?.orderMST?.customerAddress?.data?.streetNumberId
      // ),
      // geographyMSTId7: parseInt(
      //   allStates?.orderMST?.customerAddress?.data?.unitNumberId
      // ),
      // geographyMST6: {
      //   geographyName: allStates?.orderMST?.customerAddress?.data?.streetNumber,
      //   geographyCode: "Auto " + new Date().getTime() + 1,
      // },
      // geographyMST7: {
      //   geographyName: allStates?.orderMST?.customerAddress?.data?.unitNumber,
      //   geographyCode: "Auto " + new Date().getTime() + 2,
      // },
      address1: allStates?.orderMST?.customerAddress?.data?.address1,
      address2: allStates?.orderMST?.customerAddress?.data?.address2,
      unitNumber: allStates?.orderMST?.customerAddress?.data?.unitNumber,
      location: null, //"string",
      suburb: allStates?.orderMST?.customerAddress?.data?.suburb,
      latitude: allStates?.orderMST?.customerAddress?.data?.latitude,
      longitude: allStates?.orderMST?.customerAddress?.data?.longitude,
      pincode: allStates?.orderMST?.customerAddress?.data?.pincode,
    },
    couponMSTId: allStates?.cart?.voucher?.appliedVoucher?.couponMSTId,
    customerName: guestCust
      ? guestCust.customerFirstName + " " + guestCust.customerLastName
      : authCust
      ? authCust.customerFirstName + " " + authCust.customerLastName
      : "", //authUser.userMSTId,
    surcharge: getTotalOfSurcharges(
      allStates?.cart?.subTotal,
      allStates?.cart?.surcharges
    ),
    // appliedAmount: paymentObj
    //   ? (parseFloat(allStates?.cart?.subTotal ?? 0) +
    //     getTotalOfSurcharges(
    //       allStates?.cart?.subTotal,
    //       allStates?.cart?.surcharges
    //     ) +  allStates?.cart?.paymentModeCharges).toFixed(2)
    //   : 0, //allStates?.cart?.savedAmount,

    paymentModeDiscount: null,
    paymentModeCharges: parseFloat(allStates?.cart?.paymentModeCharges),
    paymentModeDiscount: null,
    //       allStates?.cart?.surcharges
    //     )).toFixed(2)
    //   : 0,
       //allStates?.cart?.savedAmount,
    appliedAmount: paymentObj
      ? (function() {
        const calculatedAmount = parseFloat(allStates?.cart?.subTotal ?? 0) +
          getTotalOfSurcharges(
            allStates?.cart?.subTotal,
            allStates?.cart?.surcharges
          ) +
          allStates?.cart?.paymentModeCharges;

        if (isNaN(calculatedAmount && allStates?.cart?.paypalOrderTotal)) {
          return parseFloat(allStates?.cart?.paypalOrderTotal).toFixed(2);
        } else {
          return calculatedAmount.toFixed(2);
        }
      })()
      : 0, //allStates?.cart?.savedAmount,
    paymentModeDiscount: null,
    paymentModeCharges: parseFloat(allStates?.cart?.paymentModeCharges),
    promoCodeDiscount: null,
    orderStageCode:
      syncType === SYNC_ORDER_TYPES.place
        ? orderMSTState.orderDateTime.data.timedOrder
          ? "DS02"
          : "DS03"
        : syncType === SYNC_ORDER_TYPES.destroy
        ? "DS12"
        : "DS01",
    active: true,
    orderDTLWebRequestSet: orderDetail,
    paymentModeWebRequestSet: paymentObj
      ? [
          {
            id: null,
            amount:  paymentObj.amount
            ? (function() {
              const calculatedAmount = parseFloat(allStates?.cart?.subTotal ?? 0) +
                getTotalOfSurcharges(
                  allStates?.cart?.subTotal,
                  allStates?.cart?.surcharges
                ) +
                allStates?.cart?.paymentModeCharges;

              if (isNaN(calculatedAmount && allStates?.cart?.paypalOrderTotal)) {
                return parseFloat(allStates?.cart?.paypalOrderTotal).toFixed(2);
              } else {
                return calculatedAmount.toFixed(2);
              }
            })()
            : 0,
            // amount: paymentObj.amount,
            //     allStates?.cart?.surcharges
            //   ) +  allStates?.cart?.paymentModeCharges).toFixed(2)
            // : 0,
            // amount: paymentObj.amount,
            status: true,
            paymentModeMSTId: paymentObj.paymentModeMSTId,
            paymentReference: paymentObj.paymentReference,
            active: true,
          },
        ]
      : [],
  };
};

const SYNC_ORDER_TYPES = {
  sync: "sync",
  place: "place-order",
  destroy: "destroy-order",
  destroyStageCode: "12",
};
const CART_TRACKING_TYPES = {
  create: "create",
  update: "update",
};
export const CART_TRACKING_PAGES = {
  default: "MENU_PAGE",
  "check-out": "CHECKOUT_PAGE",
  payment: "PAYMENT_PAGE",
  success: "SUCCESS_PAGE",
};

const syncCartTracking = (
  getState,
  dispatch,
  pageName,
  callBack = (data) => {}
) => {
  return new Promise((resolve, reject) => {
    try {
      const { cartTrackingMSTId, orderMSTObj } = getState().cart;
      if (!orderMSTObj) reject();

      // console.log(pageName);
      if (pageName) {
        const pojo = {
          cartTrackingMSTId: cartTrackingMSTId ?? null,
          cartTrackingPage: pageName,
          orderMSTId: orderMSTObj.id,
        };

        (cartTrackingMSTId
          ? cartTrackingMSTCruds.update({ body: pojo })
          : cartTrackingMSTCruds.create({ body: pojo })
        )
          .then((res) => {
            dispatch(cartSlice.actions.setErrorMessage(null));
            callBack(res.cartTrackingMSTId);
            resolve(res);
          })
          .catch((err) => {
            dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
            reject({ message: err?.message });
          });
      }
    } catch (error) {
      reject({
        message: error?.message,
      });
    }
  });
};


const clearOfferTempVar = (dispatch) => {
  dispatch(cartSlice.actions.setState({ swap: false }));
  dispatch(cartSlice.actions.setState({ add: false }));
  dispatch(cartSlice.actions.setState({ offerItemIndex: -1 }));
  dispatch(cartSlice.actions.setState({ offerId: 0 }));
  dispatch(cartSlice.actions.setState({ editGroup: "" }));
};
const addAsNonOfferItem = async (obj, dispatch) => {
  await dispatch(cartActions.addItemToCart(obj));
};
/**
 *
 * @param {any} getState
 * @param {SYNC_ORDER_TYPES} syncType
 * @returns
 */
const syncOrderDTL = (
  dispatch,
  getState,
  item,
  syncType = SYNC_ORDER_TYPES.sync
) => {
  return new Promise((resolve, reject) => {
    try {
      const { orderMSTObj } = getState().cart;
      const { orderMSTState } = getState().orderMST;
      const { currentUserTime } = getState().cart;

      if (currentUserTime) {
        let currentSystemTime = new Date().getTime();
        let diff = currentSystemTime - currentUserTime;
        diff = Math.round(diff / 1000);
        if (diff > defaultTimeDiffForClearingCart) {
          dispatch(cartSlice.actions.setNavigateToHome(true));
        } else {
          if (orderMSTObj) {
            let orderDTL = prepareItemDtl(
              item,
              syncType,
              orderMSTState,
              getState
            );
            orderDTLCruds
              .create({
                body: { ...orderDTL, orderMSTId: orderMSTObj.id },
              })
              .then((res) => {
                dispatch(cartSlice.actions.setErrorMessage(null));
                updateOrderDTLId(dispatch, getState, res);
                let getCurrentUserTime = new Date().getTime();
                dispatch(cartSlice.actions.setNavigateToHome(false));
                dispatch(
                  cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
                );
                resolve(res);
              })
              .catch((err) => {
                dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
                reject({ message: err?.message });
              });
          } else {
            try {
              syncOrder(dispatch, getState, syncType)
                .then((res) => {
                  dispatch(cartSlice.actions.setErrorMessage(null));
                  let getCurrentUserTime = new Date().getTime();
                  dispatch(cartSlice.actions.setNavigateToHome(false));

                  dispatch(
                    cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
                  );
                  resolve(res);
                })
                .catch((err) => {
                  dispatch(
                    cartSlice.actions.setErrorMessage(`${err?.message}`)
                  );
                  reject({ message: err?.message });
                });
            } catch (error) {
              reject({
                message: error?.message,
              });
            }
          }
        }
      } else {
        if (orderMSTObj) {
          let orderDTL = prepareItemDtl(item, syncType, orderMSTState);
          orderDTLCruds
            .create({
              body: { ...orderDTL, orderMSTId: orderMSTObj.id },
            })
            .then((res) => {
              updateOrderDTLId(dispatch, getState, res);
              let getCurrentUserTime = new Date().getTime();
              dispatch(cartSlice.actions.setNavigateToHome(false));

              dispatch(
                cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
              );
              resolve(res);
              dispatch(cartSlice.actions.setErrorMessage(null));
            })
            .catch((err) => {
              dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
              reject({ message: err?.message });
            });
        } else {
          try {
            syncOrder(dispatch, getState, syncType)
              .then((res) => {
                let getCurrentUserTime = new Date().getTime();
                dispatch(cartSlice.actions.setNavigateToHome(false));
                dispatch(cartSlice.actions.setErrorMessage(null));
                dispatch(
                  cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
                );
                resolve(res);
              })
              .catch((err) => {
                dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
                reject({ message: err?.message });
              });
          } catch (error) {
            reject({
              message: error?.message,
            });
          }
        }
      }
    } catch (error) {
      reject({
        message: error?.message,
      });
    }
  });
};
/**
 *
 * @param {any} getState
 * @param {SYNC_ORDER_TYPES} syncType
 * @returns
 */

const syncOrder = (dispatch, getState, syncType = SYNC_ORDER_TYPES.sync) => {
  return new Promise((resolve, reject) => {
    try {
      const { orderMSTObj, items, currentUserTime } = getState().cart;

      // changes by karan
      // it will check whether the time is greater than 15 mini or not if greater than it will clear the cart
      // getting time from localStorage and comparing it with current system time and checking the difference
      if (currentUserTime) {
        let currentSystemTime = new Date().getTime();
        let diff = currentSystemTime - currentUserTime;
        diff = Math.round(diff / 1000);
          if (diff > defaultTimeDiffForClearingCart) {
          dispatch(cartSlice.actions.setNavigateToHome(true));
        } else {
          if (orderMSTObj) {
            orderMSTCruds
              .update({
                body: {
                  orderMST: null,
                  orderMSTWebRequest: prepareOrderMSTRequest(
                    getState,
                    syncType
                  ),
                },
              })
              .then((res) => {
                dispatch(cartSlice.actions.setOrderMST(res));
                dispatch(cartSlice.actions.setErrorMessage(null));

                // changes by karan
                let getCurrentUserTime = new Date().getTime();
                dispatch(cartSlice.actions.setNavigateToHome(false));
                dispatch(
                  cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
                );
                updateOrderDTLId(dispatch, getState, res);
                resolve(res);
                //return Promise.resolve(res);
              })
              .catch((err) => {
                dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
                return Promise.reject({ message: err?.message });
              });
          }
          // else {
          //   if (getState().cart.orderCreateLoading == true) {
          //     if (getState().cart.orderCreateLoading == true) {
          //       wait();
          //     }
          //   }
          //   if (window?.location?.pathname === ROUTES.home._path) {
          //   } else {
          //     dispatch(
          //       cartSlice.actions.setState({ orderCreateLoading: true })
          //     );
          //     orderMSTCruds
          //       .create({
          //         body: {
          //           orderMST: null,
          //           orderMSTWebRequest: prepareOrderMSTRequest(
          //             getState,
          //             syncType
          //           ),
          //         },
          //       })
          //       .then((res) => {
          //         dispatch(cartSlice.actions.setOrderMST(res));
          //         // changes by karan

          //         let getCurrentUserTime = new Date().getTime();
          //         dispatch(cartSlice.actions.setNavigateToHome(false));

          //         dispatch(
          //           cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
          //         );

          //         updateOrderDTLId(dispatch, getState, res);
          //         dispatch(
          //           cartSlice.actions.setState({ orderCreateLoading: false })
          //         );
          //         resolve(res);
          //         //return Promise.resolve(res);
          //       })
          //       .catch((err) => {
          //         dispatch(
          //           cartSlice.actions.setState({ orderCreateLoading: false })
          //         );
          //         reject(err);
          //         //return Promise.reject({ message: err?.message })
          //       });
          //   }
          // }
        }
      } else {
        if (orderMSTObj) {
          orderMSTCruds
            .update({
              body: {
                orderMST: null,
                orderMSTWebRequest: prepareOrderMSTRequest(getState, syncType),
              },
            })
            .then((res) => {
              dispatch(cartSlice.actions.setOrderMST(res));
              dispatch(cartSlice.actions.setErrorMessage(null));
              // changes by karan
              let getCurrentUserTime = new Date().getTime();
              dispatch(cartSlice.actions.setNavigateToHome(false));
              dispatch(
                cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
              );
              updateOrderDTLId(dispatch, getState, res);
              resolve(res);
              //return Promise.resolve(res);
            })
            .catch((err) => {
              dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
              return Promise.reject({ message: err?.message });
            });
        } else {
          if (getState().cart.orderCreateLoading == true) {
            if (getState().cart.orderCreateLoading == true) {
              wait();
            }
          }
          
            let orderCreated = getState().cart.orderCreated;
            let orderCreateLoading = getState().cart.orderCreateLoading;

            if (!orderCreated && !orderCreateLoading) {
              dispatch(
                cartSlice.actions.setState({ orderCreateLoading: true })
              );
              // RemoveCreate
              orderMSTCruds
                .create({
                  body: {
                    orderMST: null,
                    orderMSTWebRequest: prepareOrderMSTRequest(
                      getState,
                      syncType
                    ),
                  },
                })
                .then((res) => {
                  dispatch(cartSlice.actions.setOrderMST(res));
                  // changes by karan
                  dispatch(cartSlice.actions.setErrorMessage(null));
                  let getCurrentUserTime = new Date().getTime();
                  dispatch(cartSlice.actions.setNavigateToHome(false));

                  dispatch(
                    cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
                  );

                  updateOrderDTLId(dispatch, getState, res);
                  dispatch(
                    cartSlice.actions.setState({ orderCreateLoading: false })
                  );
                  resolve(res);
                  //return Promise.resolve(res);
                })
                .catch((err) => {
                  dispatch(cartSlice.actions.setErrorMessage(`${err}`));
                  dispatch(
                    cartSlice.actions.setState({ orderCreateLoading: false })
                  );
                  reject(err);
                  //return Promise.reject({ message: err?.message })
                });
            }
          }
        }
    } catch (error) {
      // if (!items.length) {
      //     // reject({ message: null })
      //     // return;
      // }
      reject(error);
      // return Promise.reject({
      //   message: error?.message,
      // });
    }
  });
};

const handleRefund = async (state) => {
  if(state.paypalOrderId != null){
    try {
      const accessResponse = await fetch('https://api-m.paypal.com/v1/oauth2/token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_PAYPAL_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_SECRET}`)}`,
          },
        body: 'grant_type=client_credentials',
      });
      const data = await accessResponse.json();
      const accessToken = data.access_token;
      const response = await fetch(`https://api-m.paypal.com/v2/payments/captures/${state.paypalOrderId}/refund`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
              amount: {
                  value: state.paypalOrderTotal,
                  currency_code: process.env.REACT_APP_PAYPAL_CURRENCY,
              },
          }),
      });
      // console.log(response);
      if (response.ok) {
          const data = await response.json();
      } else {
          console.error('Failed to initiate refund:', response.statusText);
      }
  } catch (error) {
      console.error('Error initiating refund:', error);
  }
  }
};

const updatePayment = (
  dispatch,
  getState,
  syncType = SYNC_ORDER_TYPES.sync
) => {
  return new Promise((resolve, reject) => {
    try {
      const state = getState().cart;
      const { orderMSTObj, items, currentUserTime, paymentObj } =
        getState().cart;

      // changes by karan
      // it will check whether the time is greater than 15 mini or not if greater than it will clear the cart
      // getting time from localStorage and comparing it with current system time and checking the difference
      if (currentUserTime) {
        let currentSystemTime = new Date().getTime();
        let diff = currentSystemTime - currentUserTime;
        diff = Math.round(diff / 1000);
        if (diff > defaultTimeDiffForClearingCart && !paymentObj) {
          dispatch(cartSlice.actions.setNavigateToHome(true));
        } else {
          if (orderMSTObj) {
            orderMSTCruds
              .updateOrderPayment({
                body: {
                  orderMST: null,
                  orderMSTWebRequest: prepareOrderMSTRequest(
                    getState,
                    syncType
                  ),
                },
              })
              .then((res) => {
                dispatch(cartSlice.actions.setOrderMST(res));
                dispatch(cartSlice.actions.setErrorMessage(null));

                // changes by karan
                let getCurrentUserTime = new Date().getTime();
                dispatch(cartSlice.actions.setNavigateToHome(false));
                dispatch(
                  cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
                );
                updateOrderDTLId(dispatch, getState, res);
                resolve(res);
                //return Promise.resolve(res);
                debugger
              })
              .catch((err) => {
                console.log("Error")
                debugger
                handleRefund(state);
                dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
                return Promise.reject({ message: err?.message });
              });
          }
        }
      } else {
        if (orderMSTObj) {
          orderMSTCruds
            .updateOrderPayment({
              body: {
                orderMST: null,
                orderMSTWebRequest: prepareOrderMSTRequest(getState, syncType),
              },
            })
            .then((res) => {
              dispatch(cartSlice.actions.setOrderMST(res));
              dispatch(cartSlice.actions.setErrorMessage(null));
              // changes by karan
              let getCurrentUserTime = new Date().getTime();
              dispatch(cartSlice.actions.setNavigateToHome(false));
              dispatch(
                cartSlice.actions.setCurrentuserTime(getCurrentUserTime)
              );
              updateOrderDTLId(dispatch, getState, res);
              resolve(res);
              //return Promise.resolve(res);
              console.log(res.status)
              debugger
            })
            .catch((err) => {
              console.log("Error")
              debugger
              handleRefund(state);
              dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
              return Promise.reject({ message: err?.message });
            });
        }
      }
    } catch (error) {
      // if (!items.length) {
      //     // reject({ message: null })
      //     // return;
      // }
      reject(error);
      // return Promise.reject({
      //   message: error?.message,
      // });
    }
  });
};

const updateOrderDTLId = (dispatch, getState, response) => {
  if (
    response &&
    response.orderDTLWebRequestSet &&
    response.orderDTLWebRequestSet.length > 0
  ) {
    let orderDTLArr = response.orderDTLWebRequestSet;

    orderDTLArr.map((orderDTL) => {
      let itemArr = getState().cart.items;
      if (orderDTL.comboRefId) {
        let updateRow = itemArr.filter(
          (row) => row.id === orderDTL.comboRefId && row.offeritem
        );
        if (updateRow && updateRow.length > 0) {
          updateRow.map((offerRow) => {
            itemArr = itemArr.filter(
              (row) =>
                !(
                  row.id === orderDTL.comboRefId &&
                  row.localId === offerRow.localId
                )
            );
            let offerItemArr = [];
            offerRow.offeritem.map((n) => {
              if (
                n.id === orderDTL.itemMSTId &&
                (n.dbId == 0 ? n.localId : n.dbId) === orderDTL.orderDTLRefId
              ) {
                n = { ...n, dbId: orderDTL.id, combSeq: orderDTL.combSeq };
              }
              offerItemArr.push(n);
            });
            itemArr.push({ ...offerRow, offeritem: [...offerItemArr] });
          });
        }
      } else {
        let updateRow = itemArr.find(
          (row) => row.localId === orderDTL.orderDTLRefId
        );
        if (updateRow) {
          if (orderDTL.itemSide === 0) {
            updateRow = { ...updateRow, dbId: orderDTL.id, freshOrder: false };
          } else if (orderDTL.itemSide === 1) {
            updateRow = {
              ...updateRow,
              left: { ...updateRow.left, dbId: orderDTL.id, freshOrder: false },
            };
          } else if (orderDTL.itemSide === 2) {
            updateRow = {
              ...updateRow,
              right: {
                ...updateRow.right,
                dbId: orderDTL.id,
                freshOrder: false,
              },
            };
          }

          itemArr = itemArr.filter(
            (row) => row.localId !== orderDTL.orderDTLRefId
          );
          if (itemArr && itemArr.length > 0) {
            itemArr.push(updateRow);
          } else {
            itemArr = [updateRow];
          }
        }
      }

      // itemArr.filter((n) => !n.isHnh).map((row) => {
      //   if (row.localId === orderDTL.orderDTLRefId) {
      //     row = { ...row, dbId: orderDTL.id };
      //   }
      // });
      dispatch(cartSlice.actions.setState({ items: itemArr }));
    });
  } else if (response && response.orderDTLRefId) {
    let itemArr = getState().cart.items;
    let updateRow = itemArr.find(
      (row) => row.localId === response.orderDTLRefId
    );
    if (updateRow) {
      updateRow = { ...updateRow, dbId: response.id };
      itemArr = itemArr.filter((row) => row.localId !== response.orderDTLRefId);
      if (itemArr && itemArr.length > 0) {
        itemArr.push(updateRow);
      } else {
        itemArr = [updateRow];
      }
    }
    // itemArr.map((row) => {
    //   if (row.localId === response.orderDTLRefId) {
    //     row = { ...row, dbId: response.id };
    //   }
    // });
    dispatch(cartSlice.actions.setState({ items: itemArr }));
  }
};
export const cartActions = {
  reIniCartState: () => (dispatch) => {
    dispatch(cartSlice.actions.setVoucher({ error: null }));
    dispatch(cartSlice.actions.setSurcharges([]));
    dispatch(cartSlice.actions.catchError(null));
    dispatch(cartSlice.actions.setState({ showPopUpCart: false }));
  },
  setDeliveryCharge: (charge) => (dispatch, getState) => {
    dispatch(cartSlice.actions.setState({ deliveryCharge: charge }));
    const { subTotal, savedAmount } = calcSubTotals(getState().cart);
    dispatch(cartSlice.actions.setState({ subTotal: subTotal }));
    dispatch(cartSlice.actions.setState({ savedAmount: savedAmount }));
  },

  addOfferToCart: (obj) => async (dispatch, getState) => {
    if (obj?.recipeObj?.Toppings && obj?.recipeObj?.Toppings?.length) {
      const { totalToppings, toppingsPrice } = obj.builtPizza
        ? calcBuildYourPizzaToppings(obj.recipeObj.Toppings)
        : calcToppings(obj.recipeObj.Toppings);
      obj.totalToppings = totalToppings;
      obj.toppingsPrice = toppingsPrice;
    } else {
      obj.totalToppings = 0;
      obj.toppingsPrice = 0;
    }
    // dispatch(cartSlice.actions.startCall());
    dispatch(cartSlice.actions.addOffer(obj));

    // dispatch(cartSlice.actions.stopCall());
  },
  swapOfferItemInCartForTopping:
    (obj, combSeq) => async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
      let getCurrentUserTime = new Date().getTime();
      let diff = getCurrentUserTime - currentUserTime;
      diff = Math.round(diff / 1000);
      if (diff > defaultTimeDiffForClearingCart) {
        dispatch(cartSlice.actions.setNavigateToHome(true));
      } else {
        dispatch(cartSlice.actions.setNavigateToHome(false));
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        const offerItemIndex = getState().cart.offerItemIndex;
        const offerId = getState().cart.offerId;
        const cartItems = [...getState().cart.items];
        const offer = cartItems.filter(
          (n) => n.id && n.id === offerId && n.combinationSequence === combSeq
        );
        if (offer && offer.length) {
          const offerIdex = cartItems.indexOf(offer[0]);
          const filterGroupCode = offer[0].offeritem.filter(
            (n) => n.index === offerItemIndex
          );

          let offerItem = obj;
          if (offerItem) {
            const unfrozenItemObj = { ...offerItem.itemObj };
            unfrozenItemObj.qty = filterGroupCode[0].itemObj.qty;

            // replace old offer itm qty with new qty
            offerItem.itemObj = unfrozenItemObj;

            //  offerItem.itemObj.qty = filterGroupCode[0].itemObj.qty

            if (
              offerItem?.recipeObj?.Toppings &&
              offerItem?.recipeObj?.Toppings?.length
            ) {
              const { totalToppings, toppingsPrice } = offerItem.builtPizza
                ? calcBuildYourPizzaToppings(offerItem.recipeObj.Toppings)
                : calcToppings(offerItem.recipeObj.Toppings);
              offerItem.totalToppings = totalToppings;
              offerItem.toppingsPrice = toppingsPrice;
            } else {
              offerItem.totalToppings = 0;
              offerItem.toppingsPrice = 0;
            }
            const index = offer[0].offeritem.indexOf(filterGroupCode[0]);
            const tempObj = { ...offer[0] };
            tempObj.offeritem = replaceItemAtIndex(
              offer[0].offeritem,
              index,
              offerItem
            );
            cartItems[offerIdex] = tempObj;
            // const canSyncCartTracking = getState().cart.cartTrackingMSTId;
            // dispatch(cartSlice.actions.startCall());
            await dispatch(cartSlice.actions.updateCartItem(cartItems));
            clearOfferTempVar(dispatch);

            // syncOrder(dispatch, getState)
            //   .then((res) => {
            //     if (canSyncCartTracking)
            //       syncCartTracking(
            //         () => ({ ...getState(), orderMSTObj: res }),
            //         GetActivePageName(),
            //         (id) =>
            //           dispatch(
            //             cartSlice.actions.setState({ cartTrackingMSTId: id })
            //           )
            //       );
            //     dispatch(cartSlice.actions.setOrderMST(res));
            //     dispatch(cartSlice.actions.stopCall());
            //   })
            //   .catch((err) =>
            //     dispatch(cartSlice.actions.catchError(err?.message))
            //   );
          } else {
            addAsNonOfferItem(obj, dispatch);
            clearOfferTempVar(dispatch);
          }
          //await dispatch(cartSlice.actions.addSwapItem(obj));
        } else {
          addAsNonOfferItem(obj, dispatch);
          clearOfferTempVar(dispatch);
        }
      }
    },
    swapOfferItemInCart: (obj) => async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
    let getCurrentUserTime = new Date().getTime();
    let diff = getCurrentUserTime - currentUserTime;
    diff = Math.round(diff / 1000);
    if (diff > defaultTimeDiffForClearingCart) {
      dispatch(cartSlice.actions.setNavigateToHome(true));
    } else {
      dispatch(cartSlice.actions.setNavigateToHome(false));
      dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
      const offerItemIndex = getState().cart.offerItemIndex;
      const offerId = getState().cart.offerId;
      const cartItems = [...getState().cart.items];
      const combSeq = getState().cart.combSeqForSwap;
      const offer = cartItems.filter(
        (n) => n.id && n.id === offerId && n.combinationSequence === combSeq
      );
      if (offer && offer.length) {
        const offerIdex = cartItems.indexOf(offer[0]);
        const filterGroupCode = offer[0].offeritem.filter(
          (n) => n.index === offerItemIndex
        );
        const groupCode = filterGroupCode[0].itemGroupCode;
        let offerItem = getItemFromOffer(
          offer[0],
          obj,
          groupCode,
          offerItemIndex
        );
        if (offerItem) {
          offerItem = { ...offerItem, localId: obj.localId };
          // replace old offer itm qty with new qty
          offerItem.itemObj.qty = filterGroupCode[0].itemObj.qty;
          if (
            offerItem?.recipeObj?.Toppings &&
            offerItem?.recipeObj?.Toppings?.length
          ) {
            const { totalToppings, toppingsPrice } = offerItem.builtPizza
              ? calcBuildYourPizzaToppings(offerItem.recipeObj.Toppings)
              : calcToppings(offerItem.recipeObj.Toppings);
            offerItem.totalToppings = totalToppings;
            offerItem.toppingsPrice = toppingsPrice;
          } else {
            offerItem.totalToppings = 0;
            offerItem.toppingsPrice = 0;
          }
          const index = offer[0].offeritem.indexOf(filterGroupCode[0]);
          const tempObj = { ...offer[0] };
          tempObj.offeritem = replaceItemAtIndex(
            offer[0].offeritem,
            index,
            offerItem
          );
          cartItems[offerIdex] = tempObj;
          // const canSyncCartTracking = getState().cart.cartTrackingMSTId;
          // dispatch(cartSlice.actions.startCall());
          await dispatch(cartSlice.actions.updateCartItem(cartItems));
          clearOfferTempVar(dispatch);
          // syncOrder(dispatch, getState)
          //   .then((res) => {
          //     if (canSyncCartTracking)
          //       syncCartTracking(
          //         () => ({ ...getState(), orderMSTObj: res }),
          //         GetActivePageName(),
          //         (id) =>
          //           dispatch(
          //             cartSlice.actions.setState({ cartTrackingMSTId: id })
          //           )
          //       );
          //     dispatch(cartSlice.actions.setOrderMST(res));
          //     dispatch(cartSlice.actions.stopCall());
          //   })
          //   .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
        } else {
          addAsNonOfferItem(obj, dispatch);
          clearOfferTempVar(dispatch);
        }
        //await dispatch(cartSlice.actions.addSwapItem(obj));
      } else {
        addAsNonOfferItem(obj, dispatch);
        clearOfferTempVar(dispatch);
      }
    }
  },

  addOfferItemInCart:
    (obj, combinationSequence) => async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
      let getCurrentUserTime = new Date().getTime();
      let diff = getCurrentUserTime - currentUserTime;
      diff = Math.round(diff / 1000);
      if (diff > defaultTimeDiffForClearingCart) {
        dispatch(cartSlice.actions.setNavigateToHome(true));
      } else {
        dispatch(cartSlice.actions.setNavigateToHome(false));
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        const offerId = getState().cart.offerId;
        const itemGroup = getState().cart.editGroup;
        const cartItems = [...getState().cart.items];
        const offer = cartItems.filter(
          (n) =>
            n.id &&
            n.id === offerId &&
            n.combinationSequence === combinationSequence
        );

        if (offer && offer.length) {
          const cartIndex = cartItems.indexOf(offer[0]);

          // console.log(currentRemovedItem)

          // console.log('removedItem',cartItems[cartIndex].removedItemGroup)

          //   let removeItemQty = cartItems[cartIndex].removedItemGroup[0].data.itemObj.qty

          //   console.log(removeItemQty)

          const availInex = getAvailableIndex(offer[0].offeritem);
          let offerItem = getItemFromOffer(offer[0], obj, itemGroup, availInex);
          if (offerItem) {
            offerItem = { ...offerItem, localId: obj.localId, action: true };

            // list of removedItem can compare with item group code and get qty of our item
            let currentRemovedItem = cartItems[cartIndex].removedItemGroup.find(
              (item) => {
                return item.itemGroupCode === offerItem.itemGroupCode;
              }
            );

            offerItem.itemObj.qty = currentRemovedItem.data.itemObj.qty;

            if (
              offerItem?.recipeObj?.Toppings &&
              offerItem?.recipeObj?.Toppings?.length
            ) {
              const { totalToppings, toppingsPrice } = offerItem.builtPizza
                ? calcBuildYourPizzaToppings(offerItem.recipeObj.Toppings)
                : calcToppings(offerItem.recipeObj.Toppings);
              offerItem.totalToppings = totalToppings;
              offerItem.toppingsPrice = toppingsPrice;
            } else {
              offerItem.totalToppings = 0;
              offerItem.toppingsPrice = 0;
            }
            offer[0] = addedGroupItem(offer[0], itemGroup);
            const offerItemArr = [...offer[0].offeritem];

            offerItemArr.push(offerItem);
            cartItems[cartIndex] = { ...offer[0], offeritem: offerItemArr };

          // const canSyncCartTracking = getState().cart.cartTrackingMSTId;

          // dispatch(cartSlice.actions.startCall());
          await dispatch(cartSlice.actions.updateCartItem(cartItems));
          clearOfferTempVar(dispatch);

          // syncOrder(dispatch, getState);
          // .then((res) => {
          //   if (canSyncCartTracking)
          //     syncCartTracking(
          //       () => ({ ...getState(), orderMSTObj: res }),
          //       GetActivePageName(),
          //       (id) =>
          //         dispatch(
          //           cartSlice.actions.setState({ cartTrackingMSTId: id })
          //         )
          //     );
          //   dispatch(cartSlice.actions.setOrderMST(res));
          //   dispatch(cartSlice.actions.stopCall());
          // })
          // .catch((err) =>
          //   dispatch(cartSlice.actions.catchError(err?.message))
          // );
        } else {
          addAsNonOfferItem(obj, dispatch);
          clearOfferTempVar(dispatch);
        }
      }
    }
  },
  addItemToCart: (obj) => async (dispatch, getState) => {
    // console.log('adddddddd')
    // console.log(obj)
    if (obj?.recipeObj?.Toppings && obj?.recipeObj?.Toppings?.length) {
      // console.log("does here")
      const { totalToppings, toppingsPrice } = obj.builtPizza
        ? calcBuildYourPizzaToppings(obj.recipeObj.Toppings)
        : calcToppings(obj.recipeObj.Toppings);
      obj.totalToppings = totalToppings;
      obj.toppingsPrice = toppingsPrice;
    } else {
      obj.totalToppings = 0;
      obj.toppingsPrice = 0;
    }
    // const canSyncCartTracking = getState().cart.cartTrackingMSTId;
    dispatch(cartSlice.actions.startCall());
    obj.freshOrder = true;
    await dispatch(cartSlice.actions.addItem(obj));
    if (obj.offeritem) {
      if (!obj.fromOrderHistory) syncOrder(dispatch, getState);
      // when adding offer item in the cart we are calling cartTracking api stop calling it
      // .then((res) => {
      //   if (canSyncCartTracking)
      //     syncCartTracking(
      //       () => ({ ...getState(), orderMSTObj: res }),
      //       GetActivePageName(),
      //       (id) =>
      //         dispatch(
      //           cartSlice.actions.setState({ cartTrackingMSTId: id })
      //         )
      //     );

          //   dispatch(cartSlice.actions.stopCall());
          // })
          // .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
    } else {
      let cart = getState().cart;
      let item = cart.items.find(
        (n) => n.localId === cart.lastGeneratedId.toString()
      );
      if (!obj.fromOrderHistory) {
        syncOrderDTL(dispatch, getState, item).then((res) => {
          // if (res.id)
          //   if (cart.totalItems === 1) {
          //     orderMSTCruds.pageChange();
          //   }
        });
      }
      // for disabling cartTracking api to call upon every item adding in the cart
      // .then((res) => {
      //   if (canSyncCartTracking)
      //     syncCartTracking(
      //       () => ({ ...getState(), orderMSTObj: res }),
      //       GetActivePageName(),
      //       (id) =>
      //         dispatch(
      //           cartSlice.actions.setState({ cartTrackingMSTId: id })
      //         )
      //     );

          //   dispatch(cartSlice.actions.stopCall());
          // })
          // .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
    }
  },
  addHnhItemToCart:
    (objTmp, fromOrderHistory = false) =>
    async (dispatch, getState) => {
      let obj = {
        ...objTmp,
        left: { ...objTmp.left },
        right: { ...objTmp.right },
      };
      if (obj?.left?.recipeObj?.Toppings && obj?.right?.recipeObj?.Toppings) {
        const leftToppings = calcToppings(obj.left.recipeObj.Toppings);
        const rightToppings = calcToppings(obj.right.recipeObj.Toppings);
        obj.left.totalToppings = leftToppings.totalToppings;
        obj.left.toppingsPrice = leftToppings.toppingsPrice / 2;
        obj.right.totalToppings = rightToppings.totalToppings;
        obj.right.toppingsPrice = rightToppings.toppingsPrice / 2;
      } else {
        obj.left.totalToppings = 0;
        obj.left.toppingsPrice = 0;
        obj.right.totalToppings = 0;
        obj.right.toppingsPrice = 0;
      }
      obj.isHnh = true;
      obj.left.freshOrder = true;
      obj.right.freshOrder = true;
      await dispatch(cartSlice.actions.addItem(obj));
      if (!objTmp.fromOrderHistory) {
        dispatch(cartSlice.actions.startCall());
        syncOrder(dispatch, getState)
          .then((res) => {
            dispatch(cartSlice.actions.stopCall());
          })
          .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
      }
    },
  setQty: (localId, qty) => async (dispatch, getState) => {
    const { currentUserTime } = getState().cart;
    let getCurrentUserTime = new Date().getTime();
    let diff = getCurrentUserTime - currentUserTime;
    diff = Math.round(diff / 1000);
    if (diff > defaultTimeDiffForClearingCart) {
      dispatch(cartSlice.actions.setNavigateToHome(true));
    } else {
      dispatch(cartSlice.actions.setNavigateToHome(false));
      dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
      await dispatch(cartSlice.actions.setQty({ localId: localId, qty: qty }));
    }

    // dispatch(cartSlice.actions.startCall());
    // syncOrder(dispatch, getState)
    //   .then((res) => {
    //     dispatch(cartSlice.actions.setOrderMST(res));
    //     dispatch(cartSlice.actions.stopCall());

    //     // return new Promise((resolve, reject) => {
    //     //   try {
    //     //     const allStates = getState();
    //     //     const customerAddressDTL = {
    //     //       active: true,
    //     //       address1: null, //"string",
    //     //       address2: null, //"string",
    //     //       customerAddressDTLId: null,
    //     //       customerAddressTitle: null, //"string",
    //     //       customerMST: null,
    //     //       customerMSTId: null, //authUser.userMSTId,
    //     //       default: true,
    //     //       geoLocation: null, //"string",

    //     //       geographyMSTId3: parseInt(
    //     //         allStates?.orderMST?.customerAddress?.data?.pincodeId
    //     //       ),
    //     //       geographyMSTId4: parseInt(
    //     //         allStates?.orderMST?.customerAddress?.data?.suburbId
    //     //       ),
    //     //       geographyMSTId5: parseInt(
    //     //         allStates?.orderMST?.customerAddress?.data?.streetNameId
    //     //       ),
    //     //       streetNumber:
    //     //         allStates?.orderMST?.customerAddress?.data?.streetNumber,
    //     //       unitNumber:
    //     //         allStates?.orderMST?.customerAddress?.data?.unitNumber,
    //     //       location: null, //"string",
    //     //       pincode: allStates?.orderMST?.customerAddress?.data?.pincode,
    //     //     };

    //     //     const reqData = {
    //     //       customerAddressDTL: customerAddressDTL,
    //     //       orderMSTId: allStates?.cart?.orderMSTObj?.id,
    //     //     };

    //     //     orderMSTCruds
    //     //       .checkMinOrderValue(reqData)
    //     //       .then((res) => {
    //     //         resolve(res);
    //     //       })
    //     //       .catch((err) => reject({ message: err?.message }));
    //     //   } catch (error) {
    //     //     reject({
    //     //       message: error?.message,
    //     //     });
    //     //   }
    //     // });
    //   })
    //   .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
  },
  swapOfferItem: (e) => async (dispatch, getState) => {
    if (e.index > -1) {
      dispatch(cartSlice.actions.setState({ swap: true }));
    } else {
      dispatch(cartSlice.actions.setState({ swap: false }));
    }
    dispatch(cartSlice.actions.setState({ add: false }));
    dispatch(cartSlice.actions.setState({ offerItemIndex: e.index }));
    dispatch(cartSlice.actions.setState({ offerId: e.comboRefId }));
    dispatch(cartSlice.actions.setState({ combSeq: e.combSeq }));
    dispatch(cartSlice.actions.setState({ editGroup: e.itemGroupCode }));
    dispatch(cartSlice.actions.setState({ offerLocalId: e.offerLocalId }));
  },
  addOfferItem: (e) => async (dispatch, getState) => {
    dispatch(cartSlice.actions.setState({ swap: false }));
    if (e.comboRefId > 0) {
      dispatch(cartSlice.actions.setState({ add: true }));
    } else {
      dispatch(cartSlice.actions.setState({ add: false }));
    }
    dispatch(cartSlice.actions.setState({ offerItemIndex: e.index }));
    dispatch(cartSlice.actions.setState({ offerId: e.comboRefId }));
    dispatch(cartSlice.actions.setState({ combSeq: e.combSeq }));
    dispatch(cartSlice.actions.setState({ editGroup: e.itemGroupCode }));
    dispatch(cartSlice.actions.setState({ offerLocalId: e.offerLocalId }));
    dispatch(
      cartSlice.actions.setState({
        sizeId: e?.category?.recipeObj?.Size?.sortOrder,
      })
    );
  },
  setOfferAction: (action) => (dispatch, getState) => {
    dispatch(cartSlice.actions.offerAction(action));
  },
  setSwapAction: (action) => (dispatch, getState) => {
    dispatch(cartSlice.actions.swapAction(action));
  },
  deleteOfferItem:
    (localId, id, data, combSeq) => async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
      let getCurrentUserTime = new Date().getTime();
      let diff = getCurrentUserTime - currentUserTime;
      diff = Math.round(diff / 1000);
      if (diff > defaultTimeDiffForClearingCart) {
        dispatch(cartSlice.actions.setNavigateToHome(true));
      } else {
        dispatch(cartSlice.actions.setNavigateToHome(false));
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        let items = [...getState().cart.items];
        let matchedOffer = items.filter(
          (n) => n.id === id && n.combinationSequence === combSeq
        );

        if (matchedOffer && matchedOffer.length) {
          let removedItemArr = [...matchedOffer[0].removedItemGroup];
          let groupCode = matchedOffer[0].offeritem.filter(
            (n) => n.index === localId
          )[0]?.itemGroupCode;
          if (groupCode) {
            let groupName =
              getState().menuPage?.allItems?.data[groupCode]?.group
                ?.itemGroupName;
            if (removedItemArr.length) {
              let removeEntity = removedItemArr.filter(
                (n) => n.itemGroupCode === groupCode
              );
              if (removeEntity && removeEntity.length) {
                let index = removedItemArr.indexOf(removeEntity[0]);
                removedItemArr = replaceItemAtIndex(removedItemArr, index, {
                  itemGroupCode: groupCode,
                  numberOfitems: removeEntity[0].numberOfitems + 1,
                  groupName: groupName,
                  index: localId,
                  data: data,
                });
              } else {
                removedItemArr.push({
                  itemGroupCode: groupCode,
                  numberOfitems: 1,
                  groupName: groupName,
                  index: localId,
                  data: data,
                });
              }
            } else {
              removedItemArr.push({
                itemGroupCode: groupCode,
                numberOfitems: 1,
                groupName: groupName,
                index: localId,
                data: data,
              });
            }

            await dispatch(
              cartSlice.actions.addRemovedItemGroup({
                arr: removedItemArr,
                id: id,
                combSeq,
              })
            );
          }
        }
        await dispatch(
          cartSlice.actions.deleteOfferItem({
            localId: localId,
            id: id,
            combSeq,
          })
        );
      }
    },
  calTotalItem: (data) => async (dispatch, getState) => {
    await dispatch(cartSlice.actions.calTotalItem({ data: data }));
  },

  deleteItem: (localId) => async (dispatch, getState) => {
    const { currentUserTime } = getState().cart;

    let getCurrentUserTime = new Date().getTime();
    let diff = getCurrentUserTime - currentUserTime;
    diff = Math.round(diff / 1000);
    if (diff > defaultTimeDiffForClearingCart) {
      dispatch(cartSlice.actions.setNavigateToHome(true));
    } else {
      dispatch(cartSlice.actions.setNavigateToHome(false));
      dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
      await dispatch(cartSlice.actions.deleteItem({ localId: localId }));
    }
    // const canSyncCartTracking = getState().cart.cartTrackingMSTId;

    // dispatch(cartSlice.actions.startCall());
    // syncOrder(dispatch, getState)
    //   // for disabling cartTracking on every delete from cart
    //   .then((res) => {
    //     if (canSyncCartTracking)
    //       syncCartTracking(
    //         () => ({ ...getState(), orderMSTObj: res }),
    //         GetActivePageName(),
    //         (id) =>
    //           dispatch(cartSlice.actions.setState({ cartTrackingMSTId: id }))
    //       );
    //     dispatch(cartSlice.actions.setOrderMST(res));
    //     dispatch(cartSlice.actions.stopCall());
    //   })
    //   .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
  },
  setQtyOfOfferTopping:
    (localId, toppingId, qty, index, side = "") =>
    async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
      let getCurrentUserTime = new Date().getTime();
      let diff = getCurrentUserTime - currentUserTime;
      diff = Math.round(diff / 1000);
      if (diff > defaultTimeDiffForClearingCart) {
        dispatch(cartSlice.actions.setNavigateToHome(true));
      } else {
        dispatch(cartSlice.actions.setNavigateToHome(false));
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        await dispatch(
          cartSlice.actions.setQtyOfTopping({
            localId: localId,
            toppingId: toppingId,
            qty: qty,
            index: index,
            side,
          })
        );
      }
      // dispatch(cartSlice.actions.startCall());
      // syncOrder(dispatch, getState)
      //   .then((res) => {
      //     dispatch(cartSlice.actions.setOrderMST(res));
      //     dispatch(cartSlice.actions.stopCall());
      //   })
      //   .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
    },
  setQtyOfTopping:
    (localId, toppingId, qty, side = "") =>
    async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
      let getCurrentUserTime = new Date().getTime();
      let diff = getCurrentUserTime - currentUserTime;
      diff = Math.round(diff / 1000);
      if (diff > defaultTimeDiffForClearingCart) {
        dispatch(cartSlice.actions.setNavigateToHome(true));
      } else {
        dispatch(cartSlice.actions.setNavigateToHome(false));
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        await dispatch(
          cartSlice.actions.setQtyOfTopping({
            localId: localId,
            toppingId: toppingId,
            qty: qty,
            side,
          })
        );
      }

      // dispatch(cartSlice.actions.startCall());
      // syncOrder(dispatch, getState)
      //   .then((res) => {
      //     dispatch(cartSlice.actions.setOrderMST(res));
      //     dispatch(cartSlice.actions.stopCall());
      //   })
      //   .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
    },
  deleteTopping:
    (localId, toppingId, side = "", index) =>
    async (dispatch, getState) => {
      const { currentUserTime } = getState().cart;
      let getCurrentUserTime = new Date().getTime();
      let diff = getCurrentUserTime - currentUserTime;
      diff = Math.round(diff / 1000);
      if (diff > defaultTimeDiffForClearingCart) {
        dispatch(cartSlice.actions.setNavigateToHome(true));
      } else {
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        dispatch(cartSlice.actions.setNavigateToHome(false));
        await dispatch(
          cartSlice.actions.setQtyOfTopping({
            localId: localId,
            toppingId,
            qty: 0,
            side,
            index,
          })
        );
      }

      // dispatch(cartSlice.actions.startCall());
      // syncOrder(dispatch, getState)
      //   .then((res) => {
      //     dispatch(cartSlice.actions.setOrderMST(res));
      //     dispatch(cartSlice.actions.stopCall());
      //   })
      //   .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
    },
  replaceItem: (obj) => async (dispatch, getState) => {
    if (obj.recipeObj.Toppings && obj.recipeObj.Toppings.length) {
      const { totalToppings, toppingsPrice } = obj.builtPizza
        ? calcBuildYourPizzaToppings(obj.recipeObj.Toppings)
        : calcToppings(obj.recipeObj.Toppings);
      obj.totalToppings = totalToppings;
      obj.toppingsPrice = toppingsPrice;
    } else {
      obj.totalToppings = 0;
      obj.toppingsPrice = 0;
    }
    await dispatch(cartSlice.actions.replaceItem(obj));
    dispatch(cartSlice.actions.startCall());
    syncOrder(dispatch, getState)
      .then((res) => {
        dispatch(cartSlice.actions.setOrderMST(res));
        dispatch(cartSlice.actions.stopCall());
      })
      .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
  },
  replaceHnhItem: (objTmp) => async (dispatch, getState) => {
    let obj = {
      ...objTmp,
      left: { ...objTmp.left },
      right: { ...objTmp.right },
    };
    if (obj?.left?.recipeObj?.Toppings && obj?.right?.recipeObj?.Toppings) {
      const leftToppings = calcToppings(obj.left.recipeObj.Toppings);
      const rightToppings = calcToppings(obj.right.recipeObj.Toppings);
      obj.left.totalToppings = leftToppings.totalToppings;
      obj.left.toppingsPrice = leftToppings.toppingsPrice / 2;
      obj.right.totalToppings = rightToppings.totalToppings;
      obj.right.toppingsPrice = rightToppings.toppingsPrice / 2;
    } else {
      obj.left.totalToppings = 0;
      obj.left.toppingsPrice = 0;
      obj.right.totalToppings = 0;
      obj.right.toppingsPrice = 0;
    }
    obj.isHnh = true;
    await dispatch(cartSlice.actions.replaceItem(obj));
    dispatch(cartSlice.actions.startCall());
    syncOrder(dispatch, getState)
      .then((res) => {
        dispatch(cartSlice.actions.setOrderMST(res));
        dispatch(cartSlice.actions.stopCall());
      })
      .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
  },
  applyVoucherCode: (code) => (dispatch, getState) => {
    dispatch(
      cartSlice.actions.setVoucher({
        loading: true,
        appliedVoucher: null,
        error: null,
        appliedOn: 0,
        amountToBeDeduct: 0,
      })
    );
    dispatch(cartSlice.actions.setVoucherCode(code));
    // return couponMSTCruds.byId({ id: 13 }).then(res => {
    return couponMSTCruds
      .checkCoupon({ code: code })
      .then(async (res) => {
        // dispatch(cartSlice.actions.setVoucher({ loading: false, appliedVoucher: res }))
        if (res) {
          const { items } = getState().cart;
          if (items && items.length) {
            let appliedOn = 0;
            for (let index = 0; index < items.length; index++) {
              const item = items[index];

              if (res.free === "NONE") {
                appliedOn = -1;
                break;
              } else if (res.free === "FREE_RECIPE") {
                if (item.recipeObj?.id === res.recipeMSTId) {
                  appliedOn = item.localId ?? 0;
                  break;
                }
              } else if (res.free === "FREE_ITEM") {
                if (item.itemObj.id === res.itemMSTId) {
                  appliedOn = item.localId ?? 0;
                  break;
                }
              }
            }
            if (appliedOn === 0)
              await dispatch(
                cartSlice.actions.setVoucher({
                  loading: false,
                  appliedVoucher: null,
                  error: "Voucher not applied due to item not found",
                  appliedOn: 0,
                })
              );
            else
              await dispatch(
                cartSlice.actions.setVoucher({
                  loading: false,
                  appliedVoucher: res,
                  error: null,
                  appliedOn: appliedOn,
                })
              );
          }
        } else {
          await dispatch(
            cartSlice.actions.setVoucher({
              loading: false,
              appliedVoucher: null,
              error: "Invalid Voucher",
              appliedOn: 0,
            })
          );
        }
        dispatch(cartSlice.actions.startCall());
        syncOrder(dispatch, getState)
          .then((res) => {
            dispatch(cartSlice.actions.setOrderMST(res));
            dispatch(cartSlice.actions.stopCall());
          })
          .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
      })
      .catch(async (err) => {
        await dispatch(
          cartSlice.actions.setVoucher({
            loading: false,
            appliedVoucher: null,
            error: err?.message,
            appliedOn: 0,
          })
        );
        dispatch(cartSlice.actions.startCall());
        syncOrder(dispatch, getState)
          .then((res) => {
            dispatch(cartSlice.actions.setOrderMST(res));
            dispatch(cartSlice.actions.stopCall());
          })
          .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
      });
  },
  deleteVoucherCode: () => (dispatch, getState) => {
    dispatch(
      cartSlice.actions.setVoucher({
        loading: true,
        appliedVoucher: null,
        error: null,
        appliedOn: 0,
        amountToBeDeduct: 0,
      })
    );

    dispatch(cartSlice.actions.startCall());
    syncOrder(dispatch, getState)
      .then((res) => {
        dispatch(cartSlice.actions.setOrderMST(res));
        dispatch(cartSlice.actions.stopCall());
      })
      .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));

    dispatch(
      cartSlice.actions.setVoucher({
        loading: false,
        appliedVoucher: null,
        error: null,
        appliedOn: 0,
        amountToBeDeduct: 0,
      })
    );
  },
  //  divyesh >> remove because removal of cart tracking
  syncOrderAndCartTracking: () => (dispatch, getState) => {
    const totalItems = getState().cart.totalItems;

    if (totalItems > 0) {
      const canSyncCartTracking = getState().cart.cartTrackingMSTId;
      dispatch(cartSlice.actions.startCall());
      syncOrder(dispatch, getState)
        .then((res) => {
          // divyesh >> remove because removal of cart tracking
          if(process.env.REACT_APP_CART_TRACKING === "true"){
            if (canSyncCartTracking) {
              syncCartTracking(
                () => ({ ...getState(), orderMSTObj: res }),
                dispatch,
                GetActivePageName(),
                (id) =>
                  dispatch(cartSlice.actions.setState({ cartTrackingMSTId: id }))
              ).then(() => {
                orderMSTCruds.pageChange();
              });
            } else {
              syncCartTracking(
                () => ({ ...getState(), orderMSTObj: res }),
                dispatch,
                GetActivePageName(),
                (id) =>
                  dispatch(cartSlice.actions.setState({ cartTrackingMSTId: id }))
              ).then(() => {
                orderMSTCruds.pageChange();
              });
            }
          }
          dispatch(cartSlice.actions.setOrderMST(res));
          dispatch(cartSlice.actions.stopCall());
        })
        .catch((err) => dispatch(cartSlice.actions.catchError(err?.message)));
    }
  },

  setVoucherCode: (code) => (dispatch) => {
    dispatch(cartSlice.actions.setVoucherCode(code));
  },
  loadSurcharges: () => (dispatch, getState) => {
    return surchargeDTLCruds
      .getByOutletId()
      .then(async (res) => {
        if (res && res.length) {
          await dispatch(
            cartSlice.actions.setSurcharges(
              res.filter((sur) => {
                if (sur.halfNHalf) return true;

                const today = new Date();
                const time = formatTime(today);
                const date = formatDate(today, "yyyy-mm-dd");

                let isValid = true;

                if (sur.day) {
                  if ((sur.day === 7 ? 0 : sur.day) !== today.getDay())
                    isValid = false;
                } else if (
                  isValid &&
                  sur.toTime &&
                  sur.fromTime &&
                  sur.fromDate &&
                  sur.toDate
                ) {
                  if (
                    sur.fromTime <= time &&
                    sur.toTime >= time &&
                    sur.fromDate <= date &&
                    sur.toDate >= date
                  )
                    isValid = true;
                  else isValid = false;
                } else if (isValid && sur.toTime && sur.fromDate) {
                  if (sur.fromTime <= time && sur.toTime >= time)
                    isValid = true;
                  else isValid = false;
                } else if (isValid && sur.toDate && sur.fromDate) {
                  if (sur.fromDate <= date && sur.toDate >= date)
                    isValid = true;
                  else isValid = false;
                }
                return isValid;
              })
            )
          );
          // while (getState().cart.loading == true) {
          //   await resolveAfter2Seconds();
          // }
          // dispatch(cartSlice.actions.startCall());
          // syncOrder(dispatch, getState)
          //   .then((res) => {
          //     dispatch(cartSlice.actions.setOrderMST(res));
          //     dispatch(cartSlice.actions.stopCall());
          //     syncCartTracking(getState, GetActivePageName());
          //   })
          //   .catch((err) =>
          //     dispatch(cartSlice.actions.catchError(err?.message))
          //   );
        }
      })
      .catch((err) => {
        dispatch(
          cartSlice.actions.catchError(
            err?.message ?? "Something went wrong while getting surcharges"
          )
        );
      });
  },
  setShowPopUpCart: (val) => (dispatch) => {
    dispatch(cartSlice.actions.setState({ showPopUpCart: val }));
  },
  setInvoicePayment: (obj) => async (dispatch, getState) => {
    await dispatch(cartSlice.actions.setState({ paymentObj: obj }));
    return new Promise((resolve, reject) => {
      debugger
      dispatch(cartSlice.actions.startCall());
      updatePayment(dispatch, getState, SYNC_ORDER_TYPES.place)
        .then((res) => {
          dispatch(cartSlice.actions.setState({ placedOrderMSTObj: res }));
          dispatch(cartSlice.actions.setOrderMST(res));
          dispatch(cartSlice.actions.stopCall());
          //  divyesh >> remove because removal of cart tracking
          if(process.env.CART_TRACKING_FLAG){
            syncCartTracking(
              getState,
              dispatch,
              CART_TRACKING_PAGES.success
            ).then(() => orderMSTCruds.pageChange());
          }
          let tmp = { ...initialState };
          delete tmp.placedOrderMSTObj;
          dispatch(cartSlice.actions.setState(tmp));
          resolve(res);
        })
        .catch((err) => {
          dispatch(cartSlice.actions.catchError(err?.message));
          reject(err);
        });
    });
  },
  clearCart: () => async (dispatch, getState) => {
    const totalItems = getState().cart.totalItems;
    if (totalItems >= 0) {
      let tmp = { ...initialState };
      delete tmp.placedOrderMSTObj;
      dispatch(cartSlice.actions.setState(tmp));

      // for removing orderType from local storage in persist:cart

      dispatch(orderMSTActions.resetOrderType());
      dispatch(orderMSTActions.resetCustomerAddress());
      dispatch(orderMSTActions.resetDateTime());
      dispatch(orderMSTActions.stoptOrderType());
    } else {
      return Promise.reject();
    }
  },
  destroyOrderAndClear: () => async (dispatch, getState) => {
    const orderMST = getState().cart.orderMSTObj;
    const totalItems = getState().cart.totalItems;
    if (orderMST) {
      const { id } = orderMST;
      const data = {
        documentStageId: 12,
        orderMSTID: id,
      };
      return new Promise((resolve, reject) => {
        dispatch(cartSlice.actions.startCallForUpdateStage());
        orderMSTCruds
          .updateStage(data)
          .then((res) => {
            dispatch(cartSlice.actions.stopCallForUpdateStage());
            let tmp = { ...initialState };
            delete tmp.placedOrderMSTObj;
            dispatch(cartSlice.actions.setState(tmp));

            // for removing orderType from local storage in persist:cart

            dispatch(orderMSTActions.resetOrderType());
            dispatch(orderMSTActions.resetCustomerAddress());
            dispatch(orderMSTActions.resetDateTime());
            dispatch(orderMSTActions.stoptOrderType());

            resolve(res);
          })
          .catch((err) => {
            dispatch(cartSlice.actions.catchError(err?.message));
            reject(err);
          });
      });
    } else if (!orderMST && totalItems >= 0) {
      let tmp = { ...initialState };
      delete tmp.placedOrderMSTObj;
      dispatch(cartSlice.actions.setState(tmp));

      // for removing orderType from local storage in persist:cart

      dispatch(orderMSTActions.resetOrderType());
      dispatch(orderMSTActions.resetCustomerAddress());
      dispatch(orderMSTActions.resetDateTime());
      dispatch(orderMSTActions.stoptOrderType());
    } else {
      return Promise.reject();
    }
  },
  //  divyesh >> remove because removal of cart tracking
  syncCartTracking: (page) => (dispatch, getState) => {
    const totalItems = getState().cart.totalItems;
    if (totalItems > 0) {
      syncCartTracking(getState, dispatch, page, (id) =>
        dispatch(cartSlice.actions.setState({ cartTrackingMSTId: id }))
      )
        .then(() => orderMSTCruds.pageChange())
        .catch();
    }
  },
  CheckOutIntruction: (orderInst, delInst) => (dispatch, getState) => {
    dispatch(cartSlice.actions.setState({ otherInstrucation: orderInst }));
    dispatch(cartSlice.actions.setState({ deliveyInstrucation: delInst }));
  },
  createOrder: () => async (dispatch, getState) => {
    let cnt = 0;
    // if (getState().cart.orderCreateLoading == true) {
    //   while (getState().cart.orderCreateLoading == true && cnt<5) {
    //     await resolveAfter2Seconds();
    //     cnt++;
    //   }
    // }
    if (getState().cart.orderCreateLoading == true) {
      await resolveAfter2Seconds();
    }
    dispatch(cartSlice.actions.setState({ orderCreateLoading: true }));
    dispatch(cartSlice.actions.startCall());
    dispatch(cartSlice.actions.setOrderCreated(true));
    await orderMSTCruds
      .create({
        body: {
          orderMST: null,
          orderMSTWebRequest: prepareOrderMSTRequest(
            getState,
            SYNC_ORDER_TYPES.sync
          ),
        },
      })
      .then((res) => {
        // changes by karan
        let getCurrentUserTime = new Date().getTime();
        dispatch(cartSlice.actions.setCurrentuserTime(getCurrentUserTime));
        dispatch(cartSlice.actions.setOrderMST(res));
        dispatch(cartSlice.actions.setErrorMessage(null));

        // syncCartTracking(
        //   () => ({ ...getState(), orderMSTObj: res }),
        //   GetActivePageName(),
        //   (id) =>
        //     dispatch(cartSlice.actions.setState({ cartTrackingMSTId: id }))
        // );

        dispatch(cartSlice.actions.stopCall());
        dispatch(cartSlice.actions.setState({ orderCreateLoading: false }));
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(cartSlice.actions.setErrorMessage(`${err?.message}`));
        dispatch(cartSlice.actions.setOrderCreated(false));
        return Promise.reject({ message: err?.message });
      });
  },
  //  divyesh >> remove because removal of cart tracking
  destroyOrderOnLoad: () => async (dispatch, getState) => {
    //return new Promise((resolve, reject) => {
    try {
      const { cartTrackingMSTId, orderMSTObj } = getState().cart;
      if (!orderMSTObj || !cartTrackingMSTId) return Promise.reject();
      const pojo = {
        cartTrackingMSTId: cartTrackingMSTId ?? null,
        cartTrackingPage: CART_TRACKING_PAGES.default,
        orderMSTId: orderMSTObj.id,
      };

      await cartTrackingMSTCruds
        .manageCart({ body: pojo })
        .then((res) => {
          //callBack(res.cartTrackingMSTId);
          if (!res) {
            dispatch(cartSlice.actions.setState({ cartTrackingMSTId: null }));
            dispatch(cartSlice.actions.setState({ orderMSTObj: null }));
            dispatch(cartSlice.actions.setState({ items: [] }));
            const { subTotal, savedAmount } = calcSubTotals(getState().cart);
            dispatch(cartSlice.actions.setState({ subTotal: subTotal }));
            dispatch(cartSlice.actions.setState({ savedAmount: savedAmount }));
          }
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject({ message: err?.message });
        });
    } catch (error) {
      return Promise.reject({ message: error?.message });
    }
    //});
  },
  destroyOrderOnUnLoad: () => async (dispatch, getState) => {
    //return new Promise((resolve, reject) => {
    try {
      const { cartTrackingMSTId, orderMSTObj } = getState().cart;
      if (!orderMSTObj || !cartTrackingMSTId) return Promise.reject();
      const pojo = {
        cartTrackingMSTId: cartTrackingMSTId ?? null,
        cartTrackingPage: CART_TRACKING_PAGES.default,
        orderMSTId: orderMSTObj.id,
      };

      await cartTrackingMSTCruds
        .manageCart({ body: pojo })
        .then((res) => {
          //callBack(res.cartTrackingMSTId);
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject({ message: err?.message });
        });
    } catch (error) {
      return Promise.reject({ message: error?.message });
    }
    //});
  },
  destroyOrderOnUnLoadFetch: () => async (dispatch, getState) => {
    //return new Promise((resolve, reject) => {
    try {
      let { orderMSTObj, cartTrackingMSTId } = getState().cart;
      // if (!orderMSTObj || !cartTrackingMSTId) return Promise.reject();

      if (localStorage.getItem("persist:cart")) {
        if (
          JSON.parse(localStorage.getItem("persist:cart"))?.cartTrackingMSTId
        ) {
          cartTrackingMSTId = JSON.parse(
            localStorage.getItem("persist:cart")
          ).cartTrackingMSTId;
        }
      }

      if (!orderMSTObj || !cartTrackingMSTId) return Promise.reject();

      const pojo = {
        cartTrackingMSTId: cartTrackingMSTId ?? null,
        cartTrackingPage: CART_TRACKING_PAGES.default,
        orderMSTId: orderMSTObj.id,
      };

      const url =
        process.env.REACT_APP_API_URL + `/cartTrackingMST/manageCart/`;
      const token = localStorage.getItem("pizza-web-user-token");

      await fetch(url, {
        method: "POST",
        keepalive: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(pojo),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.data.status) {
            return Promise.resolve(res.data);
          } else {
            return Promise.reject({
              message: errorMessageFormatter(res.data),
              status: res.status,
            });
          }
        })
        .catch((err) => {
          return Promise.reject({
            message: err.message,
            status: err?.response?.status,
          });
        });
    } catch (error) {
      return Promise.reject({ message: error?.message });
    }
    //});
  },
  checkMinOrderValue: () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      try {
        const allStates = getState();
        const customerAddressDTL = {
          active: true,
          // address1: null, //"string",
          // address2: null, //"string",
          customerAddressDTLId: null,

          customerAddressTitle: null, //"string",
          customerMST: null,
          customerMSTId: null, //authUser.userMSTId,
          default: true,
          geoLocation: null, //"string",

          geographyMSTId3: parseInt(
            allStates?.orderMST?.customerAddress?.data?.pincodeId
          ),
          geographyMSTId4: parseInt(
            allStates?.orderMST?.customerAddress?.data?.suburbId
          ),
          geographyMSTId5: parseInt(
            allStates?.orderMST?.customerAddress?.data?.streetNameId
          ),
          address1: allStates?.orderMST?.customerAddress?.data?.address1,
          address2: allStates?.orderMST?.customerAddress?.data?.address2,
          suburb: allStates?.orderMST?.customerAddress?.data?.suburb,
          latitude: allStates?.orderMST?.customerAddress?.data?.latitude,
          longitude: allStates?.orderMST?.customerAddress?.data?.longitude,
          unitNumber: allStates?.orderMST?.customerAddress?.data?.unitNumber,
          location: null, //"string",
          pincode: allStates?.orderMST?.customerAddress?.data?.pincode,
        };

        const reqData = {
          customerAddressDTL: customerAddressDTL,
          orderMSTId: allStates?.cart?.orderMSTObj?.id,
        };

        // dispatch(cartSlice.actionCheckMinOrder());
        dispatch(cartSlice.actions.startCallForCheckMinOrder());

        orderMSTCruds
          .checkMinOrderValue(reqData)
          .then((res) => {
            dispatch(cartSlice.actions.stopCallForCheckMinOrder());
            resolve(res);
          })
          .catch((err) => {
            dispatch(cartSlice.actions.stopCall());
            reject({ message: err?.message });
          });
      } catch (error) {
        reject({
          message: error?.message,
        });
      }
    });
  },
};