import React, { useEffect, useState } from "react";
import "./orderPreference.scss";
import delIcon from "../../assets/svg-icons/Delivery_2.svg";
import pickIcon from "../../assets/svg-icons/Pick-up-2.svg";
import quickOrder from "../../assets/svg-icons/Order History-2.svg";
import { useDispatch, useSelector } from "react-redux";
import { outletShiftActions } from "../../_redux/files/outletShiftRedux";
import { geographyMSTActions } from "../../_redux/files/geographyMSTRedux";
import { homeActions } from "../../_redux/files/homeRedux";
import DeliveryDialog from "./DeliveryDialog";
import PickupDialog from "./PickupDialog";
import { useHistory } from "react-router-dom";
import ROUTES from "../../constants/ROUTES";

const OrderPreference = () => {
  const [open, setOpen] = useState("");
  const [address, setAddress] = useState()
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState();
  const data = useSelector((state) => state.auth.authUser);
  let history = useHistory();

  const custData = useSelector((state) => state.auth.authCust);



  let { getOutletShiftData, getAllData, getOutletShiftDataLoading, orderTypeRedux,add } = useSelector((s) => {
    return {
      getOutletShiftData: s.outletShift.outletShiftDtl.data,
      getOutletShiftDataLoading: s.outletShift.outletShiftDtl.loading,
      getAllData: s.geographyMST.geography,
      orderTypeRedux:s.orderMST.orderType.data,
      add: s
    };
  });



  const outletAddress = useSelector((state) => state.outletMST.currentOutlet.data?.[0]?.address1);




  const storedAddress = sessionStorage.getItem("address");
  let mainAddress = JSON.parse(storedAddress);


  useEffect(() => {
    setAddress(mainAddress?.address1)

  },[storedAddress])

  const openDialog = (e) => {
    //dispatch(homeActions.setOrderType(e));
    setOpen(e);
    setOrderType(e)
  };
  const closeOrderTypeDialog = () => {
    //dispatch(homeActions.setOrderType(e));
    setOpen("");
  };
  const confirm = (e) => {
    //No Action Perform
  };
  const orderHistoryClick = () => {
    history.push(ROUTES.userAccount.orderHistory._path);
  };
  const authSuccess =
    data === null
      ? false
      : data.ipAddress !== data.userName && data.signUp === true
        ? true
        : false;

      // added 4 becuase we have remover quick order
  const colSize = !authSuccess ? "6" : "6";
  // uncomment below when quick order enable
  // const colSize = !authSuccess ? "6" : "4";
  return (
    <>
    <div className="orderPreference">
      {/* <div className="row no-gutters">
        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2"> */}
      <div className="paper-container row">
        {/* removerd quick order because of order history */}
        {/* {authSuccess ? (
          <div
            className={`paper col-" + ${colSize} + " paper-left`}
            onClick={() => orderHistoryClick()}
          >
            <img src={quickOrder} alt="Quick" />
            <span className="paper-text">
              QUICK
              <b>ORDER</b>
            </span>
          </div>
        ) : null} */}
        <div
          className={`paper col-${colSize}  paper-left ${orderTypeRedux === "OT02" ? 'selectedOrderType' : '' }` }
          // className="paper col-4 paper-left"
          onClick={() => openDialog("delivery")}
        >
          <img src={delIcon} alt="Delivery" />
          <span className="paper-text">
            ORDER
            <b>DELIVERY</b>
          </span>
        </div>
        <div
          className={`paper col-${colSize} paper-right   ${orderTypeRedux === "OT01" ? 'selectedOrderType' : ''}` }
          // className="paper col-4 paper-right"
          onClick={() => openDialog("pickup")}
        >
          <img src={pickIcon} alt="Pickup" />
          <span className="paper-text">
            ORDER
            <b>PICK-UP</b>
          </span>
        </div>

      </div>
      {/* {
  history.location.pathname === "/" ? (
      <div className="changeAddress">
        <div className="changeAddress__wrapper">
           <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9999 13.4299C13.723 13.4299 15.1199 12.0331 15.1199 10.3099C15.1199 8.58681 13.723 7.18994 11.9999 7.18994C10.2768 7.18994 8.87988 8.58681 8.87988 10.3099C8.87988 12.0331 10.2768 13.4299 11.9999 13.4299Z" stroke="#262626" stroke-width="1.5"/>
<path d="M3.6202 8.49C5.5902 -0.169998 18.4202 -0.159997 20.3802 8.5C21.5302 13.58 18.3702 17.88 15.6002 20.54C13.5902 22.48 10.4102 22.48 8.3902 20.54C5.6302 17.88 2.4702 13.57 3.6202 8.49Z" stroke="#262626" stroke-width="1.5"/>
</svg>
<div className="changeAddress__span">
             <span className="">{address}</span>
     </div>
             </div>
             <Link  to={"/outlet-selection"}>
             <button className="outlet__changeBtn">CHANGE</button>
             </Link>
            </div>

             ) : null
              } */}
      {/* </div>
      </div> */}
    </div>

{
  // history.location.pathname === "/outlet-selection" ? (
    <>
    <DeliveryDialog
    open={open === "delivery"}
    closeDialog={closeOrderTypeDialog}
    redirect={true}
    confirm={confirm}
  />
  <PickupDialog
    open={open === "pickup"}
    closeDialog={closeOrderTypeDialog}
    redirect={true}
    confirm={confirm}
  />
  </>
  // ) : null
}

    </>
  );
};

export default OrderPreference;
