import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockButton from "../../componets/FormComponent/BlockButton";
import { feedbackMSTActions } from '../../_redux/files/feedBackRedux'
import StarRating from "../../componets/FormComponent/StarRating";
import { orderMSTActions } from "../../_redux/files/orderMSTRedux";

import "./Feedback.scss";
import Swal from 'sweetalert2'

const Feedback = ({ rateProductQlty = true }) => {
  const dispatch = useDispatch();
  const [feedBack, setFeedBack] = useState("");
  const [rating, setRating] = useState(0);
  const [qualityRating, setQualityRating] = useState(0);
  const custData = useSelector((state) => state.auth.authCust);
  const tempData = useSelector((state) => state.orderMST.tempUser);
  const { loading, data, error } = useSelector(
    (state) => state.orderMST.runningOrder
  );
  const OrderStageEnum = {
    PREORDER: "DS01",
    ORDER: "DS02",
    MAKE: "DS03",
    OVEN: "DS04",
    RACK: "DS05",
    READY: "DS06",
    PICKEDUP: "DS07",
    ASSIGN: "DS08",
    ONROAD: "DS09",
    DELIVERY: "DS10",
    CANCEL: "DS11",
    VOID: "DS12",
    RECEIVED: "DS13",
  };
  useEffect(() => {
    window.scroll(0,0)
    if(custData!=null)
    dispatch(orderMSTActions.getRunningOrder(custData.customerMSTId));
  console.log(data);
  }, [])
  const giveRating = (rating) => {
    setRating(rating);
  };
  const giveQualityRating = (rating) => {
    setQualityRating(rating);
  };
  const clear = () => {
    setRating(0);
    setQualityRating(0);
    setFeedBack("")
  }
  const submitFeedback = () => {
    let custId = custData ? custData.customerMSTId :  tempData? tempData.data?.customerMSTId : null
    dispatch(feedbackMSTActions.siteFeedbackSubmit(rating, qualityRating, feedBack, custId))
      .then((res) => {
        clear();
        return  Swal.fire({
          title: 'Submitted Successfully',
          icon: 'success',  
        })
      })
      .catch((err) => {

      });
  }
  const changeFeedback = (e) => {
    setFeedBack(e.target.value)
  }


  if(loading)
  {
    if (loading) {
      return (
        <div className="container">
          <div className="row mt-3">
            <div className="col-12">
              <div className="page-title text-center">Feedback</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  else{
    return (
      <div className="container">
        <div className="row mt-3">
          <div className="col-12">
            <div className="page-title text-center">Feedback</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-4 offset-lg-4 col-sm-12">
            <div className="grey-text text-center">
              Rate your ordering experience with Us.
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-4 offset-lg-4 col-sm-12">
            <div className="star-rating  text-center">
              <StarRating
                name="react-star-rating"
                caption="Rate this component!"
                totalStars={5}
                value={rating}
                setStarRating={giveRating}
              />
            </div>
          </div>
        </div>

        {custData!=null && data!=null && (data[0]?.orderStatusCode==OrderStageEnum.READY||data[0]?.orderStatusCode==OrderStageEnum.DELIVERY||data[0]?.orderStatusCode==OrderStageEnum.PICKEDUP) && rateProductQlty && (
          <>
            <div className="row mt-4">
              <div className="col-lg-4 offset-lg-4 col-sm-12">
                <div className="grey-text  text-center">Rate our product quality.</div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4 offset-lg-4 col-sm-12">
                <div className="star-rating  text-center">
                  <StarRating
                    name="react-star-rating"
                    caption="Rate this component!"
                    totalStars={5}
                    value={qualityRating}
                    setStarRating={giveQualityRating}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row mt-5">
          <div className="col-lg-4 offset-lg-4 col-sm-12">
            <div className="grey-text  text-center"> Your suggestions will help us to improve.</div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-4 offset-lg-4 col-sm-12">
            <textarea type="textarea" style={{ width: "100%" }} value={feedBack} onChange={(e) => changeFeedback(e)} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-4 offset-lg-4 col-sm-12  text-center">
            <BlockButton
              type="submit"
              text="Submit Feedback"
              className="yellow-btn"
              click={submitFeedback}
              style={{maxWidth:'250px'}}
            />
          </div>
        </div>
      </div>
    );
  }
};
export default Feedback;
